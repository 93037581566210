import React, { useState, useEffect } from "react";
import videoThumb from '../../images/fav-new.png';
import { Link } from "react-router-dom";
import { commonAxios } from "../../global/CommonAxios";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert";
import SweetAlert from "react-bootstrap-sweetalert";
import { setAllCourseIds } from "../../actions/courseAction";

const VideoCard = (props) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)


    const [courses, setCourses] = useState([])
    const [loader, setLoader] = useState(false)
    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');

    const [selectedId, setSelectedId] = useState({
        id: -1,
        courseName: ""
    });

    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };
    const fetchCourses = () => {
        setLoader(true)
        commonAxios("courses-list", {}, dispatch, config)
            .then((res) => {
                if (res.status) {
                    let val = res.data.reverse()
                    let coursesId = []
                    val.forEach((curElem) => {
                        coursesId.push(curElem.courseId)
                    })
                    dispatch(setAllCourseIds(coursesId))
                    if (props.setTotalCourses) {
                        props.setTotalCourses(val.length)
                    }
                    if (props.showRecord) {
                        val = val.slice(0, props.showRecord)
                        setCourses(val)
                    } else {
                        setCourses(val)
                    }
                } else {
                    if (res.msg === "Not found course") {
                        setCourses([])
                    }
                }
                setLoader(false)
            }).catch((error) => {
                console.log(error)
                setLoader(false)
            })
    }

    const handleDelete = (id, name) => {
        setSelectedId({
            ...selectedId,
            id: id,
            courseName: name
        })
        setShowDelete(true)
    }

    const onConfirm = () => {
        let data = {
            id: selectedId.id,
            type: "courses"
        }
        setButton("Deleting...")
        commonAxios("delete-record", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.msg, "success"))
                    fetchCourses()
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setButton("Delete")
                setShowDelete(false)

            }).catch((error) => {
                dispatch(setAlert(error.message, "danger"))
                setButton("Delete")
                setShowDelete(false)
                console.log(error)
            })
    }

    useEffect(() => {
        fetchCourses()
    }, [])
    return (
        <>
            <Alert />
            <div className="row mt-5 mb-5">
                {courses.length > 0 ?
                    courses.filter((item) => {
                        if (props.searchKey && !props.showRecord) {
                            return item.projectName.toLowerCase().includes(props.searchKey.toLowerCase())
                        }
                        else {
                            return item
                        }
                    }).map((curElem) => {
                        return (
                            <div className="col-xl-3 col-md-6 col-sm-12" key={curElem.courseId}>
                                <div className="videoProject-single">
                                    <div className="videoProject-single-top ">
                                        <div className="video-card-wrapper-1 dashboard-card-bg">
                                            {curElem.thumbnail === "" ?
                                                <div className="video-card-content ">
                                                    <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                                                </div> :
                                                <div className="video-card-content-2 ">
                                                    <img className="mb-3  img-fluid" src={curElem.thumbnail} style={{ height: "100%", width: "100%" }} />
                                                </div>
                                            }
                                        </div>

                                        <div className="vidHover">
                                            <div><p>{curElem.created}</p></div>
                                            <div className="vidiconAll">

                                                <div className="vidHover-single">
                                                    <Link to={`/chapters?courseId=${curElem.courseId}`}>
                                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                                        <span>Edit</span>
                                                    </Link>
                                                </div>
                                                {auth.user.is_client_account !== "1" ?
                                                    <div className="vidHover-single cursor-pointer" onClick={() => handleDelete(curElem.courseId, curElem.projectName)}>
                                                        <a>
                                                            <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                                            <span>Delete</span>
                                                        </a>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="videoProject-single-bottom">
                                        <span>{curElem.projectName}</span>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                    : !loader ? <div className="col-md-12">
                        <div className="text-center text-muted ">
                            <h4>You do not have any course created in your account</h4>
                        </div>
                    </div> : ''}

                {
                    loader ?
                        <div className="col-md-12 text-center" >
                            <i className="fa fa-spinner fa-spin" style={{ color: "#6d02f8", fontSize: "34px" }} />
                        </div> : ''
                }
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={(e) => onConfirm(e)}
                onCancel={(e) => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Deleting the course "{selectedId.courseName}" will delete all chapters of it
            </SweetAlert>
        </>
    )
}

export default VideoCard;