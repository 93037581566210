import React from 'react'
import Background from './Background'
import ImageLayer from './ImageLayer'
import TextLayer from './TextLayer'
import VideoLayer from './VideoLayer'
import { useDispatch } from "react-redux"
import { rePositionLayer, resizeLayer, updateSelectedLayer } from '../../../actions/chapterAction';
import Watermark from './Watermark'
import Logo from './Logo'
const PreviewMain = (props) => {
    const dispatch = useDispatch()
    const handleSelectLayer = (index) => {
        dispatch(updateSelectedLayer(index, props.selectedSlideIndex))
    }

    const handleResize = (width, height, layerIndex) => {
        width = parseInt(width.replace("px", ""))
        height = parseInt(height.replace("px", ""))
        dispatch(resizeLayer(width, height, layerIndex, props.selectedSlideIndex))
    }
    const handleRePosition = (top, left, layerIndex) => {
        dispatch(rePositionLayer(top, left, layerIndex, props.selectedSlideIndex))
    }

    return (
        <>
            <Background
                background={props.selectedSlide.background}
            />
            <Logo logo={props.logo} />
            <Watermark watermark={props.watermark} />
            {props.selectedSlide.layers.map((layer, index) => {
                if (layer.type === "text") {
                    return (
                        <TextLayer
                            layer={layer}
                            selectedSlide={props.selectedSlide}
                            selectedSlideIndex={props.selectedSlideIndex}
                            layerIndex={index}
                            handleSelectLayer={handleSelectLayer}
                            handleResize={handleResize}
                            handleRePosition={handleRePosition}
                        />
                    )
                }
                if (layer.type === "image") {
                    return (
                        <ImageLayer
                            layer={layer}
                            selectedSlide={props.selectedSlide}
                            selectedSlideIndex={props.selectedSlideIndex}
                            layerIndex={index}
                            handleSelectLayer={handleSelectLayer}
                            handleResize={handleResize}
                            handleRePosition={handleRePosition}
                        />
                    )
                }
                if (layer.type === "video") {
                    return (
                        <VideoLayer
                            layer={layer}
                            selectedSlide={props.selectedSlide}
                            selectedSlideIndex={props.selectedSlideIndex}
                            layerIndex={index}
                            handleSelectLayer={handleSelectLayer}
                            handleResize={handleResize}
                            handleRePosition={handleRePosition}
                        />
                    )
                }
            })}
        </>
    )
}

export default PreviewMain