import React, { useState, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import { useSelector, useDispatch } from "react-redux";
import Alert from "../Alert";
import queryString from "query-string";
import { useEffect } from "react";
import { commonAxios } from "../../global/CommonAxios";
import { setAlert } from "../../actions/alert";
import { useHistory } from "react-router-dom";
import { addChapters, addCourse, updateChapterText, updateChapterTextIfFalse } from "../../actions/courseAction";
import { IoReloadSharp } from "react-icons/io5";
import CourseHeader from "./CourseHeader";

const WriteChapters = ({ location }) => {

    const history = useHistory()
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const scriptRef = useRef();
    const selectedChapterId = queryString.parse(location.search).id
    const course = useSelector(state => state.course)
    const [chapterName, setChapterName] = useState("")
    const [aiSuggestion, setAiSuggestion] = useState([])
    const [slideLength, setSlideLength] = useState(0)
    const [limit, setLimit] = useState(2)
    const [loader, setLoader] = useState({
        save: false,
        ai: false,
        generate: false,
    })
    const [content, setContent] = useState({
        topic: "",
        text: "",
        copyStatus: false
    })

    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };

    useEffect(() => {
        if (content.text !== "") {
            let data = []
            if (content.text.includes("¶")) {
                data = content.text.split("¶")
            } else {
                data = content.text.split(".")
            }
            data = data.filter((curElem) => {
                return curElem !== ""
            })
            setSlideLength(data.length)
        }
        else {
            setSlideLength(0)
        }
    }, [content.text])

    const handleChange = (e) => {
        const { name, value } = e.target
        setContent({
            ...content,
            [name]: value
        })

        const chapter = course.chapters.find(({ chapterId }) => chapterId === selectedChapterId)
        if (!chapter.createdFrom.enable) {
            dispatch(updateChapterTextIfFalse(e.target.value, selectedChapterId))
        }
        else {
            dispatch(updateChapterText(e.target.value, selectedChapterId))
        }

    }

    const fetchChapter = (id) => {
        let data = { "courseId": id }
        commonAxios("chapters-fetch", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(addChapters(res.data))
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }).catch(err => console.log(err))
    }

    const handleGenerate = (type) => {

        let mydata = course.chapters.find(({ chapterId }) => chapterId === selectedChapterId)
        let brakeSymbol = content.text.includes("¶") ? "separator" : "dots"
        let data = {
            courseId: course.courseId,
            chapterId: selectedChapterId,
            text: mydata.createdFrom.text,
            slideBreak: brakeSymbol,
            buttonType: type,
            createdFrom: mydata.createdFrom
        }
        if (content.text !== "") {
            if (type === "generate") {
                setLoader({
                    ...loader,
                    generate: true
                })
            } else {
                setLoader({
                    ...loader,
                    save: true
                })
            }

            commonAxios("slide-create", data, dispatch, config)
                .then((res) => {
                    if (res.status) {
                        if (type === "generate") {
                            history.push(`/editor?courseId=${course.courseId}&chapterId=${selectedChapterId}`)
                        } else {
                            dispatch(setAlert(res.msg, "success"))
                        }
                    } else {
                        dispatch(setAlert(res.msg, "danger"))
                    }
                    setLoader({
                        ...loader,
                        save: false,
                        generate: false
                    })
                }).catch(err => {
                    setLoader({
                        ...loader,
                        save: false,
                        generate: false
                    })
                    console.log(err)
                })
        } else {
            type === "generate" ?

                dispatch(setAlert("Please write something about this chapter to Generate Slides", "danger"))
                :
                dispatch(setAlert("Please write something about this chapter to Save Slides", "danger"))
        }

    }

    const getAiData = (cName) => {
        let data
        if (content.topic !== "") {
            data = {
                text: content.topic,
                type: "article",
                limit: parseInt(limit) - 1,
                courseId: course.courseId,
                chapterId: selectedChapterId
            }
        }
        else {
            data = {
                text: cName,
                type: "article",
                limit: limit,
                courseId: course.courseId,
                chapterId: selectedChapterId
            }
        }
        setLoader({
            ...loader,
            ai: true
        })
        commonAxios("openai-call", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    handleArticle(res.data)
                    setLimit(data.limit)
                    fetchChapter(course.courseId)
                }
                else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader({
                    ...loader,
                    ai: false
                })
            }).catch((error) => {
                setLoader({
                    ...loader,
                    ai: false
                })
                console.log(error)
            })
    }
    const copyText = (text, trueIndex) => {
        navigator.clipboard.writeText(text)
        let data = [...aiSuggestion]
        data.forEach((curElem, index) => {
            if (index === trueIndex) {
                curElem.copyStatus = true
            }
            else {
                curElem.copyStatus = false
            }
        })
        setAiSuggestion(data)
    }


    const handleKeyUp = (e) => {

        if (e.ctrlKey && e.keyCode === 13) {
            const [start, end] = [scriptRef.current.selectionStart, scriptRef.current.selectionEnd];
            let textData = content.text
            textData = textData.substring(0, start) + " ¶ " + textData.substring(end, textData.length)
            setContent({
                ...content,
                text: textData
            })
            const chapter = course.chapters.find(({ chapterId }) => chapterId === selectedChapterId)
            if (!chapter.createdFrom.enable) {
                dispatch(updateChapterTextIfFalse(textData, selectedChapterId))
            }
            else {
                dispatch(updateChapterText(textData, selectedChapterId))
            }
        }
    }

    useEffect(() => {
        if (selectedChapterId) {
            const chapter = course.chapters.find(({ chapterId }) => chapterId === selectedChapterId)

            if (chapter.createdFrom.enable === false && chapter.article_limit !== "0") {
                getAiData(chapter.name)
            }
            setChapterName(chapter.name)
            setLimit(chapter.article_limit)
            let textData = ""
            if (chapter.createdFrom.enable) {
                if (chapter.createdFrom.type === "text") {
                    textData = chapter.createdFrom.source
                } else {
                    textData = chapter.createdFrom.text
                }
                setContent({
                    ...content,
                    text: textData,
                    topic: chapter.name
                })
            } else {
                setContent({
                    ...content,
                    topic: chapter.name
                })
            }
            let data = {
                courseId: course.courseId,
                type: "article",
            }


            commonAxios("course-ai-data", data, dispatch, config)
                .then((res) => {
                    if (res.status) {
                        if (res.data.length > 0) {
                            handleArticle(res.data)
                        }
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
    }, [selectedChapterId])

    const handleArticle = (data) => {
        let objData = []
        data.forEach((curElem) => {
            let obj = {
                text: "",
                copyStatus: false
            }
            obj.text += curElem.output_string.replace(/(?:\r\n|\r|\n)/g, "")
            objData.push(obj)
        })

        setAiSuggestion(objData.reverse())
    }

    const handelSlideMax = () => {
        dispatch(setAlert("You can have maximum 15 slides in a chapter.", "danger"))
    }

    useEffect(() => {
        if (course.chapters.length > 0) {
            const chapter = course.chapters.find(({ chapterId }) => chapterId === selectedChapterId)
            setLimit(chapter.article_limit)
        }
    }, [course.chapters])


    return (
        <>
            <TitleBar title="Create Course" />
            <Navbar />
            <Alert />
            <section className="siteWrap">
                <div className="container">
                    <div className="cont-research">
                        <div className="cont-research-head">
                            <div className="research-head-left" style={{ maxWidth: "800px" }}>
                                <h2>
                                    <CourseHeader
                                        courseName={course.projectName}
                                        courseId={course.courseId}
                                        topicName={course.topicName}
                                        config={config}
                                    />
                                    | {course.topicName} | {chapterName}</h2>
                            </div>
                            <div className="research-head-right">
                                <button
                                    className="backBtn mx-2"
                                    onClick={() => handleGenerate("save")}
                                >{loader.save ? <> Saving <i className="fa fa-spinner fa-spin mr-2" /> </> : "Save Script"}</button>
                                {slideLength < 16 ?
                                    <button
                                        className="backBtn research-btn "
                                        onClick={() => handleGenerate("generate")}
                                    >{loader.generate ? <> Converting <i className="fa fa-spinner fa-spin mr-2" /> </> : "Convert To Video"}</button> :
                                    <button
                                        className="backBtn research-btn "
                                        onClick={() => handelSlideMax()}
                                    >Convert To Video</button>
                                }

                            </div>
                        </div>

                        <div className="cont-research-body modified">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="research-body-left">
                                        <div className="research-block research-block-top">
                                            <div className="research-block-top-left">
                                                <div className="search-icon">
                                                    <input
                                                        type="text"
                                                        name="topic"
                                                        placeholder="Name of The Chapter"
                                                        value={content.topic}
                                                        onChange={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="research-block-top-right">
                                                <div className="search-icon text-center text-md-center text-lg-end">
                                                    <button
                                                        className=" research-btn"
                                                        onClick={() => getAiData()}
                                                        disabled={parseInt(limit) === 0 ? true : false}
                                                        style={parseInt(limit) === 0 ? { background: "gray" } : {}}
                                                    >
                                                        {aiSuggestion !== "" && !loader.ai ?
                                                            <>
                                                                <IoReloadSharp style={{ fontSize: "35px", transform: " rotate(172deg)", marginLeft: '-30px' }} />

                                                                <a style={{ position: "relative", top: "1px", left: "-20px", fontSize: "13px" }}>{limit}</a>
                                                            </> : ''}
                                                        {loader.ai ?
                                                            <>

                                                                Creating Content<i className="fa fa-spinner fa-spin text-light" style={{ position: "static", marginLeft: "4px" }} />
                                                            </> : 'Create Content'}

                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                        {aiSuggestion.length > 0 ?
                                            <div className="research-block recomnd cont-scroll">
                                                <ul>
                                                    {aiSuggestion.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div className="recomnd-single">
                                                                    <h6 id="aiContent">{curElem.text}</h6>

                                                                    <a
                                                                        className="demoLink text-light" onClick={() => copyText(curElem.text, index)}
                                                                    >
                                                                        {curElem.copyStatus ? "Copied" : "Copy"}
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="research-body-right">
                                        <div className="research-block">
                                            <div className="txtEdit">
                                                <div className="txtEdit-top">
                                                    <h5 style={{ fontSize: "18px", fontWeight: "400" }}>To separate slides press<span style={{ color: "#e512c9" }}> Ctrl + Enter</span></h5>
                                                </div>
                                                <div className="txtEdit-main">
                                                    <textarea
                                                        type="text"
                                                        value={content.text}
                                                        name="text"
                                                        onChange={(e) => handleChange(e)}
                                                        placeholder="Write Your Content Here"
                                                        onKeyUp={handleKeyUp}
                                                        ref={scriptRef}
                                                    />
                                                </div>
                                                <div className="txtEdit-foot">
                                                    <p className="text-right">Slides: {slideLength}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default WriteChapters;