import React from 'react'

const Watermark = (props) => {
    const myStyle = {
        height: props.watermark.height,
        width: props.watermark.width
    }
    return (

        props.watermark.src !== "" ?
            <div className={`watermark-op logo-${props.watermark.pos} logo-style`}>
                <img
                    src={props.watermark.src}
                    alt="watermark"
                    style={myStyle}
                />
            </div>
            : ''
    )
}

export default Watermark