import React from 'react';
import { Tab, Nav } from "react-bootstrap";
import ImageMedia from './ImageMedia';
import VideoMedia from './VideoMedia';
import UploadComponent from '../../uploadFiles/UploadComponent';


const Media = ({ slideData }) => {
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const slectedslide = slideData.find(({ isSelected }) => isSelected === "1")
    const selectedLayerIndex = slideData[selectedSlideIndex].layers.findIndex(({ isSelected }) => isSelected === true)

    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example" defaultActiveKey="image-tab">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="image-tab">Images</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="video-tab">Video</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-tab">Upload</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="image-tab">
                            <ImageMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                slectedslide={slectedslide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="video-tab">
                            <VideoMedia
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                slectedslide={slectedslide}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="upload-tab">

                            <UploadComponent
                                type="media"
                                tabType="frontMedia"
                                selectedSlideIndex={selectedSlideIndex}
                                selectedLayerIndex={selectedLayerIndex}
                                slectedslide={slectedslide}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};



export default Media;
