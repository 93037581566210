import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { layerDuplicate, removeLayer, updateLayerPos, updateSelectedTiles } from '../../../actions/chapterAction';
import PreviewMain from './PreviewMain';
import { ReactComponent as LayerBackIcon } from "../../../images/layer-back.svg";
import { ReactComponent as LayerFrontIcon } from "../../../images/layer-front.svg";
import { ReactComponent as DuplicateIcon } from "../../../images/duplicate.svg";
import { ReactComponent as DeleteIcon } from "../../../images/delete.svg";
import { setAlert } from '../../../actions/alert';

let bgAudio = false, voiceAudio = false, newIntervalId = false, clearPlay = false
const Preview = ({ bgData, slideData, logo, watermark }) => {

    const dispatch = useDispatch()
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
    const totalslides = slideData.length

    const [play, setPlay] = useState(false)
    const [loader, setLoader] = useState(false)
    const [second, setSecond] = useState(0)
    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });



    const handleForwardSlide = () => {
        if (totalslides - 2 >= selectedSlideIndex) {
            dispatch(updateSelectedTiles(selectedSlideIndex + 1))
        }
    }

    const handleBackwardSlide = () => {
        if (selectedSlideIndex > 0) {
            dispatch(updateSelectedTiles(selectedSlideIndex - 1))
        }
    }

    const deleteLayer = () => {
        dispatch(removeLayer(selectedSlide, selectedSlideIndex))
    }

    const duplicateLayer = () => {
        if (selectedSlide.layers.length > 0) {
            let layerData = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
            const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
            if (layerDataIndex !== -1) {
                const val = { ...layerData }
                dispatch(layerDuplicate(selectedSlideIndex, val, layerDataIndex))
            }
        }
    }
    // const handleLayerUpDown = (type) => {
    //     const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    //     if (type === "down") {
    //         if (layerDataIndex < selectedSlide.layers.length - 1) {
    //             dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "down"))
    //         }
    //     } else {
    //         if (layerDataIndex > 0) {
    //             dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "up"))
    //         }
    //     }
    // }
    const handleLayerUpDown = (type) => {
        const layerDataIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (layerDataIndex !== -1) {
            if (type === "down") {
                if (layerDataIndex > 0) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "up"))
                }
            } else {
                if (selectedSlide.layers.length - 1 > layerDataIndex) {
                    dispatch(updateLayerPos(selectedSlideIndex, layerDataIndex, "down"))
                }
            }
        }
    }

    const timerFunction = () => {
        let durToPlay = parseInt(selectedSlide.duration) > 300 ? 300 : parseInt(selectedSlide.duration)

        newIntervalId = setInterval(() => {
            setSecond(prevSec => prevSec + 1)
        }, 1000);

        clearPlay = setTimeout(() => {
            handlePauseFun()
        }, durToPlay * 1000)
    }

    const handlePlayFun = () => {
        setPlay(true)
        setLoader(true)
        if (selectedSlide.voiceOver.enable) {
            voiceAudio = new Audio(selectedSlide.voiceOver.src)
            voiceAudio.volume = selectedSlide.voiceOver.volume / 100
            voiceAudio.play().then(() => {
                timerFunction()
                if (bgData.enable) {
                    bgAudio = new Audio(bgData.source)
                    bgAudio.volume = bgData.volume / 100
                    bgAudio.play()
                }
                setLoader(false)
            })

        } else {
            if (bgData.enable) {
                bgAudio = new Audio(bgData.source)
                bgAudio.volume = bgData.volume / 100
                bgAudio.play().then(() => {
                    timerFunction()
                    setLoader(false)
                })
            }
            else {
                setLoader(false)
                setPlay(false)
                dispatch(setAlert("Please add voiceover or music to play", "warning"))
            }
        }
    }


    const handlePauseFun = () => {
        setPlay(false)
        setSecond(0)
        setTimer({
            ...timer,
            h: 0,
            m: 0
        })
        if (voiceAudio) {
            voiceAudio.pause()
            voiceAudio = false
        }
        if (bgAudio) {
            bgAudio.pause()
            bgAudio = false
        }

        clearTimeout(clearPlay)
        clearInterval(newIntervalId)
    }

    useEffect(() => {
        if (selectedSlide) {
            const data = selectedSlide.duration ? selectedSlide.duration : 0
            setTime({
                ...time,
                s: parseInt(data % 60),
                m: Math.floor(data % 3600 / 60),
                h: parseInt(data / 3600)
            })
        }
    }, [selectedSlide.duration])

    useEffect(() => {
        handlePauseFun()
    }, [selectedSlideIndex])



    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    useEffect(() => {
        return () => handlePauseFun()
    }, [])

    return (
        <div className="canvasEditor">
            <ul className="controls d-flex flex-wrap justify-content-center align-items-center">
                <li className="control-btn pr-4">
                    <a
                        id="layer-back"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Layer Back"
                        onClick={() => handleLayerUpDown("up")}
                    >
                        <svg width="22px" height="22px" className="d-block" viewBox="0 0 22 22">
                            <LayerBackIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn pr-4 mr-4 border-right">
                    <a
                        id="layer-front"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Layer Front"
                        onClick={() => handleLayerUpDown("down")}

                    >
                        <svg width="22px" height="22px" className="d-block" viewBox="0 0 22 22">
                            <LayerFrontIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn pr-4 mr-4 border-right" >
                    <a
                        id="duplicate"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Duplicate"
                        onClick={duplicateLayer}
                    >
                        <svg width="22px" height="22px" className="d-block" viewBox="0 0 22 22">
                            <DuplicateIcon />
                        </svg>
                    </a>
                </li>
                <li className="control-btn" >
                    <a
                        id="delete"
                        className="text-white cursor-pointer this-hover"
                        data-toggle="tooltip"
                        title="Delete"
                        onClick={deleteLayer}
                    >
                        <svg width="22px" height="22px" className="d-block" viewBox="0 0 22 22">
                            <DeleteIcon />
                        </svg>
                    </a>
                </li>
            </ul>
            <div className="canvasEditor-main">
                <PreviewMain
                    slideData={slideData}
                    selectedSlide={selectedSlide}
                    selectedSlideIndex={selectedSlideIndex}
                    logo={logo}
                    watermark={watermark}
                />
            </div>
            <div className="videoControll">
                <div className="row">
                    <div className="col-4">
                        <div className="videoControll-txt">
                            <span>{selectedSlideIndex + 1} / {totalslides}</span>

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="videoControll-nav">
                            <ul>
                                <li onClick={handleBackwardSlide}>
                                    <i class="fa fa-step-backward" aria-hidden="true"></i>
                                </li>
                                {
                                    play ?
                                        loader ?
                                            <i className="fa fa-spinner fa-spin" style={{
                                                fontSize: "20px",
                                                marginBottom: "3px",
                                                color: "#B88AFF"
                                            }} />
                                            : <li onClick={handlePauseFun}><i class="fa fa-solid fa-pause"></i></li> :
                                        <li onClick={handlePlayFun}><i class="fa fa-solid fa-play"></i></li>
                                }



                                <li onClick={handleForwardSlide}>
                                    <i class="fa fa-step-forward" aria-hidden="true"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="videoControll-right">
                            {play ?
                                <span>
                                    {
                                        timer.h < 10 ? '0' + timer.h : timer.h
                                    }
                                    :
                                    {
                                        timer.m < 10 ? '0' + timer.m : timer.m
                                    }
                                    :
                                    {
                                        second < 10 ? '0' + second : second
                                    }
                                </span> :
                                <span>{time.h > 9 ? "" : "0"}{time.h}:{time.m > 9 ? "" : "0"}{time.m}:{time.s > 9 ? "" : "0"}{time.s}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};




export default Preview;
