import React, { useState, useEffect } from 'react';
import { GiPauseButton } from 'react-icons/gi'
import { BsMicFill, BsPlayFill, BsStopFill } from 'react-icons/bs'
import axios from 'axios';
import { baseURL } from '../../../global/global';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import useRecorder from '../../MyRecording';
import AudioFiles from '../AudioFiles';
import { commonAxios } from '../../../global/CommonAxios';


let newIntervalId, music = false
const Recording = ({ selectedSlideIndex }) => {
    let [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording, setAudioURL] = useRecorder();

    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [play, setPlay] = useState(false)
    const [recordingData, setRecordingData] = useState([])
    const [selectedAudio, setSelectedAudio] = useState(false);
    const [second, setSecond] = useState(0)
    const [t, setT] = useState(false)
    const [audioDuration, setAudioDuration] = useState(0)
    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState({
        uploadLoader: false,
        fetchLoader: false
    })
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };

    const [timer, setTimer] = useState({
        h: 0,
        m: 0
    });

    useEffect(() => {
        if (recorder !== null) {
            setT(true)
        }
    }, [recorder])

    const handleClick = (type) => {
        if (type === "start") {
            startRecording()
        }
        if (type === "pause") {
            pauseRecording()
            setT(false)
            clearInterval(newIntervalId);
            return;
        }
        if (type === "resume") {
            resumeRecording()
            setT(true)

        }
        if (type === "stop") {
            stopRecording()
            clearInterval(newIntervalId);
            let totalTime = timer.h * 3600 + timer.m * 60 + second
            setAudioDuration(totalTime)
            setSecond(0)
            setTimer({
                ...timer,
                h: 0,
                m: 0
            })
            return;
        }

    };
    useEffect(() => {
        if (t) {
            newIntervalId = setInterval(() => {
                setSecond(prevSec => prevSec + 1)
            }, 1000);
        }
    }, [t])


    useEffect(() => {
        if (second > 59) {
            setTimer({
                ...timer,
                m: timer.m + 1
            })
            setSecond(0)
        }
        if (timer.m > 59) {
            setTimer({
                ...timer,
                h: timer.h + 1,
                m: 0
            })
        }
    }, [second])

    // let clearPause
    const handlePlay = () => {
        if (audioURL) {
            const file = URL.createObjectURL(audioURL)
            music = new Audio(file)
            music.play()
            setPlay(true)
        }
    }

    useEffect(() => {
        if (play) {
            setTimeout(() => {
                setPlay(false)
            }, (audioDuration + 1) * 1000)
        }
    }, [play])
    const handlePause = () => {
        // clearTimeout(clearPause)
        music.pause()
        setPlay(false)

    }

    const removeMedia = (id) => {
        let formData = new FormData();
        formData.append('id', id);
        commonAxios("delete-media-file", formData, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.message, 'success'));
                    fetchRecording();
                } else {
                    dispatch(setAlert(res.message, 'danger'));

                }
            }).catch((err) => {
                console.log(err)
                dispatch(setAlert('Getting server error', 'danger'));
            })
    }

    const handleUpload = () => {
        if (audioURL !== '') {
            setLoader({
                ...loader,
                uploadLoader: true
            })
            const fileName = new File([audioURL], "audio.mp3");
            const formData = new FormData();

            formData.append('upload_type', "recoding")
            formData.append('file', fileName)
            formData.append('user_id', auth.user.id)
            commonAxios("file-upload", formData, dispatch, config)
                .then((res) => {
                    if (res.status) {
                        dispatch(setAlert(res.msg, "success"))
                        fetchRecording()
                        if (setAudioURL) {
                            setAudioURL('')

                        }
                        setLoader({
                            ...loader,
                            uploadLoader: false
                        })
                    }
                    else {
                        setLoader({
                            ...loader,
                            uploadLoader: false
                        })
                        dispatch(setAlert(res.msg, "danger"))
                    }
                }).catch((err) => {
                    console.log(err)
                    setLoader({
                        ...loader,
                        uploadLoader: false
                    })
                })
        }
    }

    const fetchRecording = () => {
        const data = { type: 'recoding' }
        setLoader({
            ...loader,
            fetchLoader: true
        })
        commonAxios("fetch-user-uploaded-file", data, dispatch, config)
            .then((res) => {
                if (res.status === true) {
                    let val = res.data
                    val = val.reverse()
                    setRecordingData(val);
                }
                else {
                    // setRecordingData([])

                    if (recordingData.length === 1) {
                        setRecordingData([])
                    }
                }
                setLoader({
                    ...loader,
                    fetchLoader: false
                })
            }).catch((err) => {
                console.log(err)
                setLoader({
                    ...loader,
                    fetchLoader: false
                })
            })
    }

    useEffect(() => {
        fetchRecording()
    }, [])

    return (
        <div className="innertab">
            <div className="innertab-scroll alt">
                <div className="voice-record">
                    <div className='row'>
                        <div className="col-12">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="voice-record-time text-center">
                                <span className="mb-2 text-white">
                                    {
                                        timer.h < 10 ? '0' + timer.h : timer.h
                                    }
                                    :
                                    {
                                        timer.m < 10 ? '0' + timer.m : timer.m
                                    }
                                    :
                                    {
                                        second < 10 ? '0' + second : second
                                    }
                                </span>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div className="record-img rounded-circle text-center ">
                                    {status.start ?
                                        !status.pause ?
                                            <GiPauseButton
                                                fontSize={30}
                                                color="#000"
                                                className="cursor-pointer"
                                                onClick={() => handleClick("pause")}
                                            /> : <BsPlayFill
                                                fontSize={30}
                                                color="#000"
                                                className="cursor-pointer"
                                                onClick={() => handleClick("resume")}
                                            /> :
                                        <BsMicFill
                                            fontSize={30}
                                            color="#000"
                                            onClick={() => handleClick("start")}
                                            className="cursor-pointer"

                                        />
                                    }
                                </div>
                                <div className="record-img rounded-circle text-center text-dark">
                                    {status.start ?
                                        < BsStopFill
                                            fontSize={30}
                                            color="red"
                                            onClick={() => handleClick("stop")}

                                            className="cursor-pointer"
                                        /> : <BsStopFill
                                            fontSize={30}
                                            color="#000"
                                        />
                                    }
                                </div>
                            </div>
                            <div className="voice-block-bottom mt-4">
                                <div className="row">
                                    <div className="col-6">
                                        {play ? <button
                                            className="demoLink btn-block justify-content-center"
                                            onClick={handlePause}
                                        >
                                            Pause
                                        </button> :
                                            <button
                                                className="demoLink btn-block justify-content-center"
                                                onClick={handlePlay}
                                            >
                                                Play
                                            </button>
                                        }
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="demoLink btn-block justify-content-center"
                                            onClick={handleUpload}
                                        >
                                            {loader.uploadLoader ?
                                                <> Uploading  <i className="fa fa-spinner fa-spin mx-1" /></> : 'Upload'
                                            }

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className=' mt-3'>
                        <div className='mediaList'>
                            <ul className='row recording-wrapper'>
                                {recordingData.length > 0 ?
                                    recordingData.map((item, index) => {
                                        return (
                                            <AudioFiles
                                                selectedSlideIndex={selectedSlideIndex}
                                                title="Uploaded VoiceOver"
                                                voiceOverName={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                                                url={item.url}
                                                currentUrl={currentUrl}
                                                setCurrentUrl={setCurrentUrl}
                                                type="uploaded"
                                                removeMedia={removeMedia}
                                                selectedAudio={selectedAudio}
                                                setSelectedAudio={setSelectedAudio}
                                                id={item.id}
                                                key={index}
                                            />
                                        )
                                    }) : ''}
                                {
                                    loader.fetchLoader ?
                                        <div className="col-md-12 mt-2">
                                            <h4 className='text-center'><i className="fa fa-spinner fa-spin " style={{ color: '#e86be8' }} /></h4>
                                        </div>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </div >

    );
}

export default Recording;
