import React, { useState, useEffect } from 'react';
import { Tab, Nav, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import closeModalx from "../../../images/modal-close.png"
import axios from 'axios';
import { baseURL } from '../../../global/global';
import { setOutroStatus, removeOutro, updateIntroOutro } from '../../../actions/chapterAction';
import { setAlert } from '../../../actions/alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import HoverVideoPlayer from "react-hover-video-player";
import swal from 'sweetalert';
import sample from "../../../images/sample.png";
import { useHistory } from 'react-router-dom';
import { commonAxios } from "../../../global/CommonAxios"


const Outro = ({ videoReel, chapter, socialLength, addDataInCourseChap }) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [outroData, setOutroData] = useState(false)
    const [outroArr, setOutroArr] = useState([])
    const [showDelete, setShowDelete] = useState(false);

    const [showDeleteVideoReel, setShowDeleteVideoReel] = useState(false);
    const [button, setButton] = useState('Delete');
    const [loader, setLoader] = useState({
        outroLoader: false
    });
    const [intOut, setIntOut] = useState({
        id: false,
        type: '',
    })

    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };
    const selectOutro = (curElem, type) => {
        let data = {
            url: '',
            nameData: '',
            thumbData: ''
        }
        if (type === "videoReel") {
            data.url = curElem.prview_video_url
            data.nameData = curElem.project_name
            data.thumbData = curElem.preview_image_url
        }
        else {
            data.url = curElem.url
            data.nameData = curElem.name
            data.thumbData = curElem.poster
        }

        handleClose()
        dispatch(updateIntroOutro('UPDATE_OUTRO', data))
        addDataInCourseChap(data, "outro")
    }
    const connectVideoReel = () => {
        setShowDeleteVideoReel(true);
    }
    const onConfirmVideoReel = () => {
        history.push("/integration")
    }

    const onConfirm = () => {
        handleDelete(intOut.id, intOut.type)
    }

    const openDeletePopUp = (e, id, type) => {
        e.stopPropagation()
        setIntOut({
            ...intOut,
            id: id,
            type: type
        })
        setShowDelete(true);
    }

    useEffect(() => {
        if (chapter) {
            setOutroData(chapter.outro)
        }
    }, [chapter])

    const fetchOutro = () => {
        const video = { type: "outro" }
        commonAxios("fetch-user-uploaded-file", video, dispatch, config)
            .then((res) => {
                if (res.status) {
                    let val = res.data
                    val = val.reverse()
                    setOutroArr(val)
                }
                else {
                    if (res.data.length === 0) {
                        setOutroArr(res.data)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleLoader = (status) => {
        setLoader({
            ...loader,
            outroLoader: status
        });
    }

    const onInputVideo = (e, type) => {
        if (e.target.files[0].type === "video/mp4") {
            const allowedSize = 20000000;
            if (e.target.files[0].size < allowedSize) {
                handleLoader(true, type)
                const formData = new FormData();
                formData.append('file', e.target.files[0])
                formData.append('upload_type', type)
                formData.append('user_id', auth.user.id)

                commonAxios("file-upload", formData, dispatch, config)
                    .then((res) => {
                        if (res.status) {
                            handleIntroOutro(res.data)
                            handleLoader(false, type)
                        }
                        else {
                            dispatch(setAlert(res.msg, 'danger'));
                            handleLoader(false, type)
                        }
                    }).catch(() => {
                        handleLoader(false, type)
                    })
            } else {
                swal("Oops!", "Max allowed size for video is 20MB");
            }
        } else {
            swal("Oops!", "You have Selected Invalid File Type ");
        }
    }

    const handleStatusIntroOutro = (e) => {
        dispatch(setOutroStatus(e.target.checked))
        if (e.target.checked === false) {
            dispatch(removeOutro())
        }
    }

    const handleDelete = (id) => {
        setButton("Deleting...")
        let formData = new FormData();
        formData.append('id', id);

        commonAxios("delete-media-file", formData, dispatch, config)
            .then((res) => {
                if (res.status) {
                    fetchOutro()
                    dispatch(setAlert(res.data.message, 'success'));
                }
                setButton("Delete")
                setShowDelete(false);
            }).catch((error) => {
                setShowDelete(false);
                console.log(error)
            })
    }
    const handleIntroOutro = (dataVal) => {
        fetchOutro()
        let data = {
            url: dataVal.path,
            nameData: dataVal.name,
            thumbData: dataVal.thumbnial
          }
        dispatch(updateIntroOutro('UPDATE_OUTRO', data))
        addDataInCourseChap(data, "outro")
    }

    useEffect(() => {
        fetchOutro()
    }, []);

    return (
        <div className="add-block-single">
            <div className="add-block-main">
                <div className="add-block-title mb-4">
                    <h2>Outro</h2>
                    <div className="switch-single">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={outroData.enable}
                                onChange={(e) => handleStatusIntroOutro(e, 'outro')}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                {outroData.enable ?
                    <>
                        <div className="fileUpload" style={outroData.src ? { color: '#e86be8' } : {}}>
                            <i className="fa fa-cloud-upload" aria-hidden="true"></i>

                            {outroData.name ?
                                outroData.name.length > 23 ? `${outroData.name.slice(0, 23)}...` : outroData.name
                                : 'Upload Your Outro'}
                            <input
                                type="file"
                                className='cursor-pointer'
                                onChange={(e) => onInputVideo(e, 'outro')}
                            />
                        </div>
                        <div className="itemDisp">
                            <div className="itemDisp-left text-center">
                                <ul>
                                    <li style={{ position: 'relative' }}>
                                        {
                                            loader.outroLoader ? <i className="fa fa-spinner fa-spin loader-center" />
                                                : <img
                                                    src={outroData.thumbnail ? outroData.thumbnail : sample}
                                                    alt=""
                                                    className="uploadImg"
                                                />
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div className='itemDisp-right'>
                                <button onClick={handleShow}>Select Intro video +</button>
                            </div>
                            <Modal show={show} onHide={handleClose}>
                                <button className='model-close-button text-right' onClick={handleClose}>
                                    <img src={closeModalx} alt="" />
                                </button>
                                <Modal.Body style={{ color: '#ffffff' }}>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="tabInner">
                                                <Tab.Container id="" defaultActiveKey="intro-video">

                                                    <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded Outro videos</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="Videoreel-video" style={{ fontSize: '15px' }}>Videoreel Outro videos</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>

                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="intro-video">
                                                            <div className="innertab credit-modal" style={{ paddingRight: '20px' }}>
                                                                <div className="row gy-4">
                                                                    {outroArr.length > 0 ?
                                                                        outroArr.map((curElem) => {
                                                                            return (
                                                                                <div
                                                                                    className="col-4 pe-2 ps-1"
                                                                                    key={curElem.id}
                                                                                    onClick={() => selectOutro(curElem)}
                                                                                >
                                                                                    <div className="credit-video-card" style={{
                                                                                        border: "none",
                                                                                    }}>
                                                                                        <div className="">
                                                                                            <>
                                                                                                <HoverVideoPlayer
                                                                                                    className="my-1 cursor-pointer "
                                                                                                    videoSrc={curElem.url}
                                                                                                    pausedOverlay={
                                                                                                        <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                                                    }
                                                                                                    loadingOverlay={
                                                                                                        <div><i className="fa fa-spinner fa-spin hover-loader-center" style={{ top: '46%' }} /></div>
                                                                                                    }
                                                                                                />
                                                                                                <FiTrash2
                                                                                                    className='remove-media'
                                                                                                    onClick={(e) => openDeletePopUp(e, curElem.id, 'intro')}
                                                                                                />
                                                                                            </>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <div className='text-center' style={{ fontSize: 20 }}>
                                                                            You don't have uploaded Outro videos
                                                                        </div>}
                                                                </div>


                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>

                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="Videoreel-video">
                                                            <div className="innertab  credit-modal" style={{ paddingRight: '20px' }}>
                                                                <div className="row gy-4">
                                                                    {videoReel.length > 0 ?
                                                                        videoReel.map((curElem, index) => {
                                                                            if (curElem.category === "outro") {
                                                                                return (
                                                                                    <div
                                                                                        className="col-4 pe-2 ps-2"
                                                                                        key={index}
                                                                                        onClick={() => selectOutro(curElem, "videoReel")}
                                                                                    >
                                                                                        <div className="credit-video-card mt-2" style={{
                                                                                            border: "none",
                                                                                        }}>
                                                                                            <HoverVideoPlayer
                                                                                                className="cursor-pointer"
                                                                                                videoSrc={!curElem.prview_video_url}
                                                                                                pausedOverlay={
                                                                                                    <img src={curElem.preview_image_url} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                                                }
                                                                                                loadingOverlay={
                                                                                                    <div><i className="fa fa-spinner fa-spin hover-loader-center" style={{ top: '46%' }} /></div>
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        })
                                                                        :
                                                                        <div className='text-center ' style={{ margin: "0 auto" }}>
                                                                            {socialLength > 0 ?
                                                                                <div>
                                                                                    You haven't created your VideoReel Outro Videos yet
                                                                                </div> :
                                                                                <div className="alert alert-warning col-8 mt-4" role="alert" style={{ width: "80%", margin: '0 auto' }}>
                                                                                    You haven't connected your VideoReel account yet. Click <a className="custom-anchor"
                                                                                        onClick={() => connectVideoReel()}
                                                                                    >Here</a> to connect and use those VideoReel Outro Videos
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText={button}
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="light"
                                    title="Are you sure?"
                                    onConfirm={() => onConfirm()}
                                    onCancel={() => setShowDelete(false)}
                                    focusCancelBtn
                                    show={showDelete}
                                >
                                    You want to delete this file
                                </SweetAlert>

                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Connect"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="light"
                                    title="Are you sure?"
                                    onConfirm={() => onConfirmVideoReel()}
                                    onCancel={() => setShowDeleteVideoReel(false)}
                                    focusCancelBtn
                                    show={showDeleteVideoReel}
                                >
                                    Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations.
                                </SweetAlert>

                            </Modal>
                        </div>
                    </> : ''}
            </div>

        </div>
    );
}

export default Outro;
