import React, { useState } from 'react'
import { SketchPicker } from "react-color";
import { useDispatch } from 'react-redux';
import { updateBg } from '../../../actions/chapterAction';

const BgColor = ({ selectedSlideIndex }) => {
  const dispatch = useDispatch()
  const [color, setColor] = useState("")

  const onInputColor = (e) => {
    setColor(e.rgb)
    let hexData = e.hex.split("#")
    let file = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`
    dispatch(updateBg(file, selectedSlideIndex, "color", hexData[1]))
  }
  return (
    <div className="innertab">
      <div className="innertab-scroll">
        <div className="row">
          <div className="col-sm-12">
            <div className="sketch-picker-wrapper">
              <div className="sketch-picker-head">
                <h4>Choose a Background Color</h4>
              </div>
              <SketchPicker
                color={color}
                onChange={(e) => onInputColor(e)}
                className="color-picker-style"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BgColor