import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../actions/alert';
import { addMediaLayer, updateBg, updateMediaLayer } from '../../actions/chapterAction';
import { commonAxios } from '../../global/CommonAxios';
import HoverVideoPlayer from "react-hover-video-player";
import AudioFiles from '../editor/AudioFiles';


const FileCard = (props) => {
    const dispatch = useDispatch()
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };
    const [checkImage, setCheckImage] = useState(-1);
    const [checkVideo, setCheckVideo] = useState(-1);
    const [currentUrl, setCurrentUrl] = useState('');
    const [selectedAudio, setSelectedAudio] = useState(false);
    const [mediaId, setMediaId] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [button, setButton] = useState('Delete');

    const selectMedia = (data, index, type) => {
        if (props.tabType === "backgroundMedia") {
            let dataObj
            if (type === "image") {
                dataObj = {
                    thumbnail: data.url,
                    url: data.url
                }
                setCheckImage(index);
                setCheckVideo(-1);
                dispatch(updateBg(dataObj, props.selectedSlideIndex, type))

            } else {
                return new Promise((resolve, reject) => {
                    let au = document.createElement('video');
                    au.src = data.url
                    au.addEventListener('loadedmetadata', function () {
                        dataObj = {
                            thumbnail: data.poster,
                            url: data.url,
                            duration: Math.round(au.duration)
                        }
                        dispatch(updateBg(dataObj, props.selectedSlideIndex, type))
                        resolve(au.duration)
                        setCheckVideo(data.url);
                        setCheckImage(-1);
                    }, false);
                })
            }

        }
        else {
            let layerType

            if (type === "video") {
                return new Promise((resolve, reject) => {
                    let au = document.createElement('video');
                    au.src = data.url
                    au.addEventListener('loadedmetadata', function () {
                        setCheckImage(index);
                        data.height = (au.videoHeight)
                        data.width = (au.videoWidth)
                        if (props.slectedslide.layers.length > 0) {
                            layerType = props.slectedslide.layers[props.selectedLayerIndex].type
                            if (layerType === "image" || layerType === "video") {
                                dispatch(updateMediaLayer(data, props.selectedSlideIndex, props.selectedLayerIndex, type))
                            } else {
                                dispatch(addMediaLayer(type, props.selectedSlideIndex, data))
                            }
                        } else {
                            dispatch(addMediaLayer(type, props.selectedSlideIndex, data))
                        }
                        resolve(au.videoHeight, au.videoWidth)
                    }, false);

                })

            } else {
                if (props.slectedslide.layers.length > 0) {
                    layerType = props.slectedslide.layers[props.selectedLayerIndex].type
                    if (layerType === "image" || layerType === "video") {
                        dispatch(updateMediaLayer(data, props.selectedSlideIndex, props.selectedLayerIndex, type))
                    } else {
                        dispatch(addMediaLayer(type, props.selectedSlideIndex, data))
                    }
                } else {
                    dispatch(addMediaLayer(type, props.selectedSlideIndex, data))
                }
            }
        }
    }

    const openDeletePopUp = (id) => {
        setMediaId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        setButton("Deleting...")
        let formData = new FormData();
        formData.append('id', mediaId);

        commonAxios("delete-media-file", formData, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.msg, 'success'));
                    props.mediaType === "image" ?
                        props.fetchFiles("images") :
                        props.fetchFiles("video")

                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setShowDelete(false);
                setButton("Delete")
            }).catch(() => {
                setShowDelete(false);
                setButton("Delete")
            })
    }


    const removeMedia = (id) => {

        let formData = new FormData();
        formData.append('id', id);
        commonAxios("delete-media-file", formData, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.msg, 'success'));
                    props.fetchFiles(props.type)
                } else {
                    dispatch(setAlert(res.msg, 'danger'));
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        setSelectedAudio(false)
    }, [props.selectedSlideIndex]);

    return (
        <>
            <div className="media-ul-list">
                {
                    props.data.length > 0 ?
                        props.data.map((curElem, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {props.type === "media" ?
                                        <li className="col-md-6 cursor-pointer" key={index}>
                                            {props.mediaType === "image" ?
                                                <>
                                                    <div className="mediaList-single m-1" style={{ textAlign: "center" }}>
                                                        <img className="mediaImg" alt="" style={{ maxHeight: '100%', width: "auto", }} src={curElem.url} onClick={() => selectMedia(curElem, curElem.id, "image")} />
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <div onClick={() => selectMedia(curElem, curElem.id, "video")} key={index}>
                                                        {/* <HoverVideoPlayer
                                                            className="hover-video-player"
                                                            style={{ border: '1px solid gray', height: '110px', overflow: 'hidden' }}
                                                            videoSrc={curElem.url}
                                                            pausedOverlay={
                                                                <img
                                                                    src={curElem.poster}
                                                                    alt={curElem.name + `-${index}`}
                                                                    style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                                                            }
                                                            loadingOverlay={
                                                                <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                            }
                                                        /> */}
                                                        <HoverVideoPlayer
                                                            style={{ border: '1px solid gray', height: '110px', overflow: 'hidden' }}
                                                            className="m-1 "
                                                            key={curElem.id}
                                                            videoSrc={curElem.url}
                                                            pausedOverlay={
                                                                <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                            }
                                                            loadingOverlay={
                                                                <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            }

                                            <div className="delete-media" onClick={() => openDeletePopUp(curElem.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </div>
                                        </li>



                                        :
                                        props.type !== "media" ?
                                            props.type === "voiceover" ?
                                                <AudioFiles
                                                    selectedSlideIndex={props.selectedSlideIndex}
                                                    title="Uploaded VoiceOver"
                                                    voiceOverName={curElem.name.length > 15 ? `${curElem.name.slice(0, 15)}...` : curElem.name}
                                                    url={curElem.url}
                                                    currentUrl={currentUrl}
                                                    setCurrentUrl={setCurrentUrl}
                                                    type="uploaded"
                                                    removeMedia={removeMedia}
                                                    selectedAudio={selectedAudio}
                                                    setSelectedAudio={setSelectedAudio}
                                                    id={curElem.id}
                                                    key={index}

                                                />
                                                :
                                                <AudioFiles
                                                    title={curElem.name.length > 15 ? `${curElem.name.slice(0, 15)}...` : curElem.name}
                                                    selectedSlideIndex={props.selectedSlideIndex}
                                                    voiceOverName={curElem.title}
                                                    url={curElem.url}
                                                    selectedAudio={selectedAudio}
                                                    type="uploaded"
                                                    setSelectedAudio={setSelectedAudio}
                                                    removeMedia={removeMedia}
                                                    currentUrl={currentUrl}
                                                    id={curElem.id}
                                                    setCurrentUrl={setCurrentUrl}
                                                    key={index}
                                                />
                                            : ''
                                    }
                                </React.Fragment>
                            )
                        }) : ''
                }
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="secondary"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>
        </>
    );
};
export default FileCard;
