import logoImg from "../../../images/IMG.png";
// import Affiliate from './Affiliate'
import { BsPlayCircle, BsPauseCircle } from 'react-icons/bs'
import React, { useEffect, useMemo, useState } from "react";
import sample from "../../../images/sample.png";
import axios from "axios";
import { baseURL } from "../../../global/global";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../actions/alert";
import swal from "sweetalert";
import HoverVideoPlayer from "react-hover-video-player";
import Alert from "../../Alert";
import { addSlideDuration, aiVolumeChange, bgVolumeChange, removeLogoWatermark, updateLogoWatermark, updateStatus } from "../../../actions/chapterAction";
import { commonAxios } from "../../../global/CommonAxios";
// import { fileUpload } from "../../../actions/courseAction";

const Setting = ({ slideData }) => {

  const chapter = useSelector(state => state.chapter)
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const logoData = chapter.logo
  const waterMarkData = chapter.watermark
  const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")

  const [ttsAudio, setTtsAudio] = useState(false)
  const [bgAudio, setbgAudio] = useState(false)

  const [loadLogo, setLoadLogo] = useState(false);
  const [loadWatermark, setLoadWatermark] = useState(false);
  const [play, setPlay] = useState(false)

  const [posList, setPosList] = useState([])

  const [tts, setTts] = useState({
    url: "",
  })
  const [bgMusic, setBgMusic] = useState({
    src: "",
  })

  const [name, setName] = useState({
    logoName: 'Upload Your Logo',
    waterMarkName: 'Upload Your Watermark'
  });
  const logo = useMemo(() => {
    return logoData
  }, [logoData])

  const waterMark = useMemo(() => {
    return waterMarkData
  }, [waterMarkData])



  let config = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': localStorage.token
    },
  };
  useEffect(() => {
    setTts(selectedSlide.voiceOver)
  }, [slideData, selectedSlideIndex])

  useEffect(() => {
    setBgMusic(chapter.audio)
  }, [chapter])


  useEffect(() => {
    if (tts.enable) {
      setTtsAudio(new Audio(tts.src))
    }
    if (bgMusic.enable) {
      setbgAudio(new Audio(bgMusic.source))
    }
  }, [tts.src, bgMusic.source])


  const handlePlay = () => {
    if (tts.enable || bgMusic.enable) {
      if (tts.enable) {
        ttsAudio.volume = tts.volume / 100
        ttsAudio.play()
      }
      if (bgMusic.enable) {
        bgAudio.volume = bgMusic.volume / 100
        bgAudio.play()
      }
      setPlay(true)
    }
    else {
      dispatch(setAlert("Please add Music and VoiceOver in your slide", 'warning'))
    }
  }

  useEffect(() => {
    commonAxios("position-list", {}, dispatch, config)
      .then((res) => {
        if (res.status) {
          setPosList(res.data)
        }
      }).catch((err) => {
        console.log(err)
      })
  }, [])

  const handlePause = () => {
    if (ttsAudio) {
      ttsAudio.pause()
    }
    if (bgAudio) {
      bgAudio.pause()
    }
    setPlay(false)
  }

  useEffect(() => {
    handlePause()
  }, [selectedSlideIndex])

  const addDuration = (e) => {
    let num = e.target.value
    if (num > 300) {
      dispatch(setAlert("You can't set slide duration more than 300 sec", "warning"))
      dispatch(addSlideDuration(300))
    } else {
      dispatch(addSlideDuration(num))
    }
  }


  const onLoadFile = (file, type) => {
    if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
      if (file.size < 5000000) {

        let formData = new FormData();
        formData.append('file', file);
        formData.append('upload_type', type);
        formData.append('user_id', auth.user.id)

        type === "logo" ?
          setLoadLogo(true)
          :
          setLoadWatermark(true)
        commonAxios("file-upload", formData, dispatch, config)
          .then((res) => {
            if (res.status) {
              selectImage(res.data.path, type)
              res.data.type === "logo" ?
                setName({
                  ...name,
                  logoName: res.data.name
                }) :
                setName({
                  ...name,
                  waterMarkName: res.data.name
                })

            } else {
              dispatch(setAlert(res.msg, 'danger'))
            }

            setLoadLogo(false)
            setLoadWatermark(false)
          }).catch(() => {
            setLoadLogo(false);
            setLoadWatermark(false);
          })
      } else {
        swal("Oops!", "Max allowed size 5MB");
      }
    } else {
      swal("Oops!", "You have Selected Invalid File Type");
    }
  }

  const logoWaterPos = (position, type) => {
    let fileType = 'UPDATE_LOGO_POSITION';
    if (type === 'watermark') {
      fileType = 'UPDATE_WATERMARK_POSITION';
    }

    let posVal = position
    if (position === "top-center" || position === "bottom-center") {
      posVal = "center"
    }
    const val = posList.find(({ name }) => name === posVal)
    let dimension = {
      x: val.positionX,
      y: val.positionY
    }
    dispatch(updateLogoWatermark(fileType, position, dimension));
  }

  const selectImage = (url, type) => {

    let uploadType = 'ADD_LOGO';
    if (type === "watermark") {
      uploadType = 'ADD_WATERMARK';
    }

    dispatch(updateLogoWatermark(uploadType, url));
  }

  const handleLogoWaterMark = (e, type) => {

    if (type === 'logo') {
      dispatch(updateStatus(e.target.checked, 'logo'))
      if (e.target.checked === false) {
        setName({
          ...name,
          logoName: 'Upload Your Logo'
        })
        dispatch(removeLogoWatermark('REMOVE_LOGO_LAYER'))
      }
    }

    if (type === 'watermark') {
      dispatch(updateStatus(e.target.checked, 'watermark'))
      if (e.target.checked === false) {
        dispatch(removeLogoWatermark('REMOVE_WATERMARK_LAYER'))
        setName({
          ...name,
          waterMarkName: 'Upload Your Watermark'
        })
      }
    }
  }

  const handleVolumeChange = (e, type) => {
    handlePause()
    switch (type) {
      case 'music':
        const bgAction = 'CHANGE_BG_VOLUME'
        dispatch(bgVolumeChange(bgAction, e.target.value))
        break;

      case 'voiceover':
        const voiceAction = 'CHANGE_VOICE_VOLUME'
        dispatch(aiVolumeChange(voiceAction, e.target.value, selectedSlideIndex))
        break;

      default:
        break;
    }

  }

  return (
    <>
      <Alert />
      <div className="tabInner">
        <div className="media-block">
          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title  mb-4">
                <h2>Add Logo</h2>
                <div className="switch-single">
                  <label className="switch" htmlFor="logo">
                    <input
                      type="checkbox"
                      checked={logo.enable}
                      id="logo"
                      onChange={(e) => handleLogoWaterMark(e, "logo")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              {logo.enable ?
                <>
                  <div className="fileUpload" style={logo.src ? { color: '#fff' } : {}}>
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.logoName.length > 20 ? `${name.logoName.slice(0, 20)}...` : name.logoName}
                    <input
                      type="file"
                      className='cursor-pointer'
                      onChange={(e) => onLoadFile(e.target.files[0], "logo")}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left text-center">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {
                            loadLogo ? <i className="fa fa-spinner fa-spin loader-center" />
                              : <img
                                src={logo.src ?
                                  logo.src : sample}
                                alt=""
                                className="uploadImg"
                              />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="itemDisp-right">

                      <select onChange={(e) => logoWaterPos(e.target.value, 'logo')} className='cursor-pointer'>
                        <option value={'top-left'}>Top Left</option>
                        <option value={'top-center'}>Top Center</option>
                        <option value={'top-right'}>Top Right</option>
                        <option value={'bottom-left'}>Bottom Left</option>
                        <option value={'bottom-center'}>Bottom Center</option>
                        <option value={'bottom-right'}>Bottom Right</option>
                      </select>
                    </div>
                  </div>
                </>
                : ''}
            </div>
          </div>


          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title  mb-4">
                <h2>Add Watermark</h2>
                <div className="switch-single">
                  <label className="switch" htmlFor="watermark">
                    <input
                      type="checkbox"
                      id="watermark"
                      checked={waterMark.enable}
                      onChange={(e) => handleLogoWaterMark(e, "watermark")}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              {waterMark.enable ?
                <>
                  <div className="fileUpload" style={waterMark.src !== "" ? { color: '#fff' } : {}}>
                    <i className="fa fa-cloud-upload" aria-hidden="true"></i> {name.waterMarkName.length > 23 ? `${name.waterMarkName.slice(0, 23)}...` : name.waterMarkName}
                    <input
                      type="file"
                      id="inputGroupFile01"
                      className='cursor-pointer'
                      onChange={(e) => onLoadFile(e.target.files[0], 'watermark')}
                    />
                  </div>
                  <div className="itemDisp">
                    <div className="itemDisp-left text-center">
                      <ul>
                        <li style={{ position: 'relative' }}>
                          {
                            loadWatermark ?
                              <i className="fa fa-spinner fa-spin loader-center" />
                              : <img
                                src={waterMark.src ? waterMark.src : sample}
                                alt=""
                                className="uploadImg"
                              />
                          }
                        </li>
                      </ul>
                    </div>
                    <div className="itemDisp-right">
                      <select onChange={(e) => logoWaterPos(e.target.value, 'watermark')} className='cursor-pointer'>
                        <option value={'top-left'}>Top Left</option>
                        <option value={'top-center'}>Top Center</option>
                        <option value={'top-right'}>Top Right</option>
                        <option value={'bottom-left'}>Bottom Left</option>
                        <option value={'bottom-center'}>Bottom Center</option>
                        <option value={'bottom-right'}>Bottom Right</option>
                      </select>
                    </div>
                  </div>
                </> : ''}
            </div>
          </div>


          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title mb-4">
                <h2>Duration (Sec)</h2>
              </div>
              <input
                className="inPut"
                type="number"
                name="duration"
                min={1}
                // max={10}
                value={selectedSlide.duration}
                onChange={(e) => addDuration(e)}
              />
            </div>
          </div>

          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title mb-4">
                <h2>Volume Control</h2>
                <span style={{ color: "#fff" }}>
                  {play ? <BsPauseCircle
                    fontSize={23}
                    onClick={() => handlePause()}
                    className="cursor-pointer"
                  /> :
                    <BsPlayCircle
                      fontSize={23}
                      onClick={() => handlePlay()}
                      className="cursor-pointer"


                    />}
                </span>

              </div>
              <div>
                <h2 className="pb-2">Voiceover <span style={{ float: 'right' }}>{`${selectedSlide.voiceOver.volume}%`}</span></h2>
                <input
                  type="range"
                  min={1}
                  className="slidern cursor-pointer"
                  name="voiceOver"
                  id="myRange"
                  value={selectedSlide.voiceOver.volume}
                  onChange={e => handleVolumeChange(e, 'voiceover')}
                />

                <h2 className="pb-2 mt-4">Music  <span style={{ float: 'right' }}>{`${chapter.audio.volume}%`}</span></h2>
                <input
                  type="range"
                  min={1}
                  className="slidern cursor-pointer"
                  id="myRange"
                  name="music"
                  value={chapter.audio.volume}
                  onChange={e => handleVolumeChange(e, 'music')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default Setting;
