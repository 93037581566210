import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import VideoCard from "./VideoCard";
import Footer from "../Footer";
import TitleBar from "../TitleBar";
import CreateCourseModal from "../createCourse/CreateCourseModal";
import { setAlert } from "../../actions/alert";
import { useDispatch, useSelector } from "react-redux";
import MergeCard from "./MergeCard";
import { commonAxios } from "../../global/CommonAxios";

const Courses = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const course = useSelector((state) => state.course);
  const [searchKey, setSearchKey] = useState("");
  const [totalCourses, setTotalCourses] = useState(0);
  const [type, setType] = useState("1");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [mergeVideo, setMergeVideo] = useState([]);
  const handleClose = () => setShow(false);
  const [memberShip, setMemberShip] = useState([]);

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.token,
    },
  };

  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"));
    }
  }, [auth.user]);

  const handleShow = () => {
    if (memberShip.length === 1 && memberShip[0] === "premium") {
      if (course.coursesAll.length >= 50) {
        dispatch(setAlert("You can create max courses of 50 only", "danger"));
      } else {
        setShow(true);
      }
    } else {
      setShow(true);
    }
  };

  const handleChangeType = (val) => {
    setType(val);
  };

  const fetchMerge = () => {
    setLoader(true);
    commonAxios("fetch-merge-campaigns", {}, dispatch, config)
      .then((res) => {
        if (res.status) {
          setTotalCourses(res.data.data.length);
          setMergeVideo(res.data.data.reverse());
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (type === "2") {
      fetchMerge();
    }
  }, [type]);

  return (
    <>
      <TitleBar title="Courses" />
      <Navbar />

      <section className="siteWrap">
        <div className="projectTitle-wrap">
          <div className="container">
            <div className="projectTitle">
              <div className="projectTitle-left">
                <h2>
                  ALL COURSES |{" "}
                  <span>
                    {totalCourses} Course{totalCourses >= 2 ? "s" : ""}
                  </span>{" "}
                </h2>
              </div>
              <div className="projectTitle-right">
                <div className="mycourseInp-wrap mycourseInpBlack">
                  <select
                    className="merge-btn mycourseInp"
                    value={type}
                    onChange={(e) => handleChangeType(e.target.value)}
                  >
                    <option value={1}>Video</option>
                    <option value={2}>Merge Video</option>
                  </select>
                </div>
                <div className="project-input-box">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fas fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Projects"
                      onChange={(e) => setSearchKey(e.target.value)}
                    />
                  </div>
                </div>
                {auth.user.is_client_account !== "1" ? (
                  <button className="demoLink" onClick={handleShow}>
                    <i className="fas fa-plus-circle"></i> Create New Course
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="forproject">
            {type === "1" ? (
              <VideoCard
                searchKey={searchKey}
                setTotalCourses={setTotalCourses}
              />
            ) : (
              <div className="row mt-2">
                {mergeVideo.length > 0 ? (
                  mergeVideo
                    .filter((curElem) => {
                      if (searchKey) {
                        return curElem.name
                          .toLowerCase()
                          .includes(searchKey.toLowerCase());
                      } else {
                        return curElem;
                      }
                    })
                    .map((curElem, index) => {
                      return (
                        <MergeCard
                          fetchMerge={fetchMerge}
                          item={curElem}
                          key={index}
                        />
                      );
                    })
                ) : !loader ? (
                  <div className="no-campaign-card col-md-12">
                    <div className="no-campaign-card-box text-center text-muted mt-3">
                      <h4>
                        You do not have any "Merge Course" in your account
                      </h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {loader ? (
                  <div className="col-md-12">
                    <h4 className="text-center load-icon-help">
                      <i className="fa fa-spinner fa-spin mr-2" />
                    </h4>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>

        <CreateCourseModal show={show} handleClose={handleClose} />
      </section>

      <Footer />
    </>
  );
};

export default Courses;
