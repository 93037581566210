import { setAlert } from "./alert"



export const addSlideData = (data) => (dispatch) => {
    dispatch({ type: "ADD_SLIDE_CHAPTER", payload: data })
}

export const addSlides = (data) => (dispatch) => {
    data[0].transition.simpleTransition = "fade"
    data.forEach((curElem, index) => {
        if (curElem.background.type === "color") {
            if (!curElem.background.src.includes("#")) {
                curElem.background.src = "#" + curElem.background.src
            }
            if (!curElem.background.poster.includes("#")) {
                curElem.background.poster = "#" + curElem.background.poster
            }
        }
        if (curElem.layers === "") {
            curElem.layers = []
        }
        else {
            let index1 = curElem.layers.findIndex(({ isSelected }) => isSelected === true)
            if (index1 === -1) {
                curElem.layers[0].isSelected = true
                if (index > 0) {
                    curElem.transition.enable = true
                    curElem.transition.simpleTransition = "fade"
                }
            }
        }

    })

    const ifSelected = data.findIndex(({ isSelected }) => isSelected === "1")
    if (ifSelected === -1) {
        data[0].isSelected = "1"
    }
    dispatch({ type: "ADD_SLIDES", payload: data })
}
export const addNewSlide = (data) => (dispatch) => {
    data.transition.enable = true
    data.transition.simpleTransition = "fade"
    dispatch({ type: "NEW_SLIDE_ADD", payload: data })
}
export const removeSlides = () => (dispatch) => {
    dispatch({ type: "UNMOUNT_SLIDES", payload: false })
}

export const textLayerAdd = (slideIndex) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let layer = {
        id: randomId,
        type: "text",
        text: "You Can Edit Text Here",
        style: {
            fontColor: "#FFFFFF",
            fontFamily: "Noto Sans",
            backgroundColor: "",
            fontSize: "22",
            textAlign: "center",
            fontWeight: false,
            fontStyle: false,
            textTransform: "",
            textDecoration: "none",
            lineHeight: 1,
            opacity: 1
        },
        position: {
            left: 150,
            top: 200
        },
        size: {
            width: 300,
            height: 50
        },
        isSelected: true,
        src: "",
        meta: ""
    }
    const data = { layer, slideIndex }
    dispatch({ type: "ADD_TEXT_LAYER", payload: data })
}

export const textLayerUpdate = (text, slideIndex, layerIndex) => (dispatch) => {
    const data = { text, slideIndex, layerIndex }
    dispatch({ type: "UPDATE_LAYER_TEXT", payload: data })
}

export const updateSelectedLayer = (index, selectedSlideIndex) => (dispatch) => {
    const data = { index, selectedSlideIndex }
    dispatch({ type: "UPDATE_LAYER", payload: data })
}

export const removeLayer = (selectedSlide, selectedSlideIndex) => (dispatch) => {
    const layerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    const data = { layerIndex, selectedSlideIndex }
    dispatch({ type: "REMOVE_LAYER", payload: data })
}
export const layerDuplicate = (slideIndex, layerData, layerIndex) => (dispatch) => {
    layerData.id = Math.floor(Math.random() * 1000000000000000).toString()
    const data = { slideIndex, layerData, layerIndex }
    dispatch({ type: "COPY_LAYER", payload: data })
}


export const updateSelectedTiles = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SELECTED_TILES', payload: data });
}

export const updateSlidesData = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDES_DATA', payload: data });
}
export const handleDragStatus = () => (dispatch) => {
    dispatch({ type: 'UPDATE_DRAG_STATUS' })
}


export const updateVideoThumbnail = (data) => (dispatch) => {
    dispatch({ type: 'UPDATE_THUMBNAIL', payload: data });
}

export const removeTtsFromSlides = (selectedSlideIndex) => (dispatch) => {
    const tts = {
        text: "",
        languageId: "",
        voiceId: "",
        translateText: ""
    }
    const data = { tts, selectedSlideIndex }

    dispatch({ type: 'REMOVE_TTSAUDIO_SLIDES', payload: data })
}

export const removeBgAudioFromSlides = (slideIndex) => (dispatch) => {
    dispatch({ type: 'REMOVE_BGAUDIO_SLIDES', payload: slideIndex })
}


export const updateBg = (file, index, type, hex) => (dispatch) => {
    let data = { file, index }
    if (type === "image") {
        dispatch({ type: 'UPDATE_BG_IMAGE', payload: data })
    }
    else if (type === "video") {
        dispatch({ type: "UPDATE_BG_VIDEO", payload: data })
    }
    else {
        data = { file, hex, index }
        dispatch({ type: "UPDATE_BG_COLOR", payload: data })
    }
}

export const addMediaLayer = (type, index, val) => (dispatch) => {
    let randomId = Math.floor(Math.random() * 1000000000000000).toString()
    let width = 280, height = 150
    if (type === "video") {
        if (val.width > 720 || val.height > 720) {
            width = Math.floor(val.width / 9)
            height = Math.floor(val.height / 9)
        } else {
            width = Math.floor(val.width / 3)
            height = Math.floor(val.height / 3)
        }
    }

    const data = {
        objData: {
            id: randomId,
            type: type,
            style: {
                fontColor: "#FFFFFF",
                fontFamily: "Noto Sans",
                backgroundColor: "",
                fontSize: "17",
                textAlign: "left",
                fontWeight: false,
                fontStyle: false,
                textTransform: "lowercase",
                textDecoration: "none",
                lineHeight: 1,
                opacity: 1
            },
            position: {
                left: 30,
                top: 30
            },
            size: {
                width: width ? width : 280,
                height: height ? height : 150
            },
            isSelected: true,
            src: val.url,
            meta: ""
        },
        index
    }
    dispatch({ type: "ADD_MEDIA_LAYER", payload: data })
}

export const updateMediaLayer = (val, slideIndex, layerIndex, type) => (dispatch) => {
    if (type === "image") {
        if (val.thumbnail) {
            val = val.thumbnail
        } else {
            val = val.url
        }
        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_IMAGE", payload: data })
    } else {

        if (val.width > 720 || val.height > 720) {
            val.width = Math.floor(val.width / 9)
            val.height = Math.floor(val.height / 9)
        } else {
            val.width = Math.floor(val.width / 3)
            val.height = Math.floor(val.height / 3)
        }

        let data = { val, slideIndex, layerIndex }
        dispatch({ type: "UPDATE_LAYER_VIDEO", payload: data })
    }

}


// LOGO / WATERMARK / SETTING

export const updateStatus = (enable, type) => (dispatch) => {
    const data = { enable, type }
    dispatch({ type: 'UPDATE_STATUS_L&W', payload: data })
}
export const removeLogoWatermark = (type) => (dispatch) => {
    dispatch({ type: type })
}
export const updateLogoWatermark = (type, data, dimension) => (dispatch) => {
    const val = { data, dimension }
    dispatch({ type: type, payload: val });
}
export const bgVolumeChange = (type, value) => (dispatch) => {
    dispatch({ type: type, payload: value });
}
export const aiVolumeChange = (type, value, selectedSlideIndex) => (dispatch) => {
    const data = { value, selectedSlideIndex }
    dispatch({ type: type, payload: data });
}
export const addSlideDuration = (value) => (dispatch) => {
    dispatch({ type: 'UPDATE_SLIDE_DURATION', payload: value })
}


// CREDIT / INTRO / OUTRO


export const setIntroStatus = (status) => (dispatch) => {
    dispatch({ type: 'SET_INTRO_STATUS', payload: status })
}
export const setOutroStatus = (status) => (dispatch) => {

    dispatch({ type: 'SET_OUTRO_STATUS', payload: status })
}

export const removeIntro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_INTRO' })
}

export const removeOutro = () => (dispatch) => {
    dispatch({ type: 'REMOVE_OUTRO' })
}

export const updateIntroOutro = (type, data) => (dispatch) => {
    dispatch({ type: type, payload: data });
}


// /* Text Styles */

export const updateFontSize = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTSIZE', payload: temp });
}

export const updateFontFamily = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTSTYLE', payload: temp });
}
export const updateTextBold = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBOLD', payload: temp });
}

export const updateTextItalic = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTITALIC', payload: temp });
}

export const updateTextUpper = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTTRANSFORM', payload: temp });
}

export const updateTextLower = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTTRANSFORM', payload: temp });
}

export const updateTextUnderline = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTDECO', payload: temp });
}

export const updateTextStrike = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTDECO', payload: temp });
}


export const updateTextAlign = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTALIGN', payload: temp });
}

export const updateTextLineHeight = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTLINEHEIGHT', payload: temp });
}

export const updateFontColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTCOLOR', payload: temp });
}

export const updateFontBgColor = (data, selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let temp = { data, selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'UPDATE_TEXT_FONTBGCOLOR', payload: temp });
}


export const removeFontBgColor = (selectedSlideIndex, selectedTextIndex) => (dispatch) => {
    let data = { selectedSlideIndex, selectedTextIndex }
    dispatch({ type: 'REMOVE_TEXT_FONTBGCOLOR', payload: data });
}

export const applyToAllSlide = (data) => (dispatch) => {
    dispatch({ type: 'APPLY_TO_ALL', payload: data });
}


export const updateTtsText = (text, index) => (dispatch) => {
    const data = { text, index }
    dispatch({ type: "UPDATE_TTS1_TEXT", payload: data })
}
export const updateTtsUrl = (val, langData, index) => (dispatch) => {
    const data = { val, langData, index }
    dispatch({ type: "UPDATE_GENERATED_TTS", payload: data })
    dispatch(setAlert('Voiceover applied successfully', 'success'));
    dispatch(addSlideDuration(val.duration))
}

export const updateTransLateText = (newText, index) => (dispatch) => {
    const data = { newText, index }
    dispatch({ type: "UPDATE_TEXT_TRANSLATE", payload: data })
}
export const revertTranslate = (index) => (dispatch) => {
    dispatch({ type: "REVERT_TRANSLATE", payload: index })
}

// ======================================================

export const updateVoiceText = (text, index) => (dispatch) => {
    let data = {
        text: text,
        index: index
    }
    dispatch({ type: 'UPDATE_TTS_TEXT', payload: data });
}
export const setTtsData = (meta, selectedSlideIndex, enable) => (dispatch) => {
    const data = { meta, selectedSlideIndex, enable }
    dispatch({ type: 'ADD_TTS_DATA', payload: data });
    if (meta.src !== "") {
        dispatch(setAlert('Voiceover applied successfully', 'success'));
        dispatch(addSlideDuration(meta.duration))
    }
}

export const addBgMusic = (url, selectedSlideIndex, dur) => (dispatch) => {
    const data = { url, selectedSlideIndex, dur }
    dispatch({ type: 'ADD_BGAUDIO_DATA', payload: data })
}

export const addTransition = (name, index) => (dispatch) => {
    let data = { name, index }
    dispatch({ type: "ADD_TRANSITION", payload: data })
    dispatch(setAlert("Transition applied successfully", "success"))
}

export const deleteTile = (deleteIndex, newIndex) => (dispatch) => {
    let data = { deleteIndex, newIndex }
    dispatch({ type: "DELETE_TILE", payload: data })
}


// LAYERS EDITABLE

export const resizeLayer = (w, h, layerI, slideI) => (dispatch) => {
    const data = { w, h, layerI, slideI }
    dispatch({ type: "RESIZE_LAYER", payload: data })
}

export const rePositionLayer = (t, l, layerI, slideI) => (dispatch) => {
    const data = { t, l, layerI, slideI }
    dispatch({ type: "REPOSITION_LAYER", payload: data })
}
export const updateLayerPos = (slideIndex, layerIndex, place) => (dispatch) => {
    const data = { slideIndex, layerIndex }
    if (place === "down") {
        dispatch({ type: "LAYER_DOWN", payload: data })
    } else {
        dispatch({ type: "LAYER_UP", payload: data })
    }
}