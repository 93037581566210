import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { commonAxios } from '../../../global/CommonAxios';
import swal from "sweetalert"
import iconSave from "../../../images/icon-save.svg";
import { updateTtsText, updateTtsUrl } from '../../../actions/chapterAction';
import TranslateText from './TranslateText';
import { useSelector } from 'react-redux';

let ttsAudio = false;
const AiVoice = ({ slideData }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)
    const selectedSlide = slideData.find(({ isSelected }) => isSelected === "1");
    const selectedSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1");

    const [currentTts, setCurrentTts] = useState('');
    const [loader, setLoader] = useState(false);
    const [ttsStatus, setTtsStatus] = useState(true);
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [ttsText, setTtsText] = useState("")
    const [engine, setEngine] = useState("")
    const [dataToRevert, setDataToRevert] = useState("")
    const [addAllText, setAddAllText] = useState(false)
    const [langData, setLangData] = useState({
        lang: '',
        voice: ''
    })
    const [generateData, setGenerateData] = useState({
        url: "",
        duration: 0
    })

    const [memberShip, setMemberShip] = useState([])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])


    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };

    const generateTts = () => {
        if (ttsText !== "" && langData.lang !== "" && langData.voice !== "") {
            setLoader(true);
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                text: ttsText,
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            commonAxios("generate-ttsnew", data, dispatch, config)
                .then((res) => {
                    if (res.status) {
                        setTtsStatus(false)
                        setGenerateData({
                            ...generateData,
                            url: res.data.file_url,
                            duration: res.data.duration - 3
                        })
                    } else {
                        dispatch(setAlert(res.msg, 'danger'));
                        setTtsStatus(true)
                    }
                    setLoader(false);
                }).catch((error) => {
                    setTtsStatus(true)
                    setLoader(false);
                    console.log(error)
                })
        } else {
            swal("Oops!", "Please, add text, language & voice in TTS");
        }
    }

    const onEditTts = (e) => {
        setTtsText(e.target.value)
        setTtsStatus(true)
        dispatch(updateTtsText(e.target.value, selectedSlideIndex))
    }

    const getLanguage = (e) => {
        setTtsStatus(true)
        setLangData({
            ...langData,
            lang: e.target.value
        })
        fetchPollyVoices(e.target.value);

    }

    const getVoices = (e) => {
        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        setLangData({
            ...langData,
            voice: data.voice_id
        })
        setTtsStatus(true)
        setEngine(data.type)
    }

    const handleAddAllText = (e) => {
        setAddAllText(e.target.checked)
        let textData = ""
        if (e.target.checked) {
            selectedSlide.layers.forEach((curElem) => {
                if (curElem.type === "text") {
                    textData += curElem.text
                    textData += "\n"
                }
            })
            dispatch(updateTtsText(textData, selectedSlideIndex))
        }
        else {
            dispatch(updateTtsText("", selectedSlideIndex))
            setTtsText("")
        }
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const applyTts = () => {
        dispatch(updateTtsUrl(generateData, langData, selectedSlideIndex))
    }

    const fetchPollyLanguages = () => {
        commonAxios("get-polly-languages", {}, dispatch, config)
            .then((res) => {
                if (res.status) {
                    let arr = res.data
                    if (memberShip[0] === "premium" && memberShip.length === 1) {
                        arr = res.data.filter((curElem) => {
                            return (curElem.name.includes("English") || curElem.name.includes("French") || curElem.name.includes("Hindi") || curElem.name.includes("Spanish"))
                        })
                    }
                    setPollyLanguages({ ...pollyLanguages, data: arr });
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const fetchPollyVoices = (value) => {
        let formData = new FormData();
        formData.append('lang_code', value);
        commonAxios("get-artists", formData, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setPollyVoices({ ...pollyVoices, data: res.data });
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (selectedSlide.voiceOver.meta.translateText !== "") {
            setTtsText(selectedSlide.voiceOver.meta.translateText)
        }
        else {
            if (dataToRevert !== "") {
                setTtsText(dataToRevert)
                dispatch(updateTtsText(dataToRevert, selectedSlideIndex))
            } else {
                setTtsText(selectedSlide.voiceOver.meta.text)
            }
        }
        setTtsStatus(true)
    }, [selectedSlide.voiceOver.meta.translateText, selectedSlideIndex])

    useEffect(() => {
        setTtsText(selectedSlide.voiceOver.meta.text)
        setTtsStatus(true)
    }, [selectedSlide.voiceOver.meta.text])

    useEffect(() => {
        if (addAllText) {
            setAddAllText(false)
        }
    }, [selectedSlideIndex])

    useEffect(() => {
        fetchPollyLanguages();
        fetchPollyVoices('en-US');

        return () => {
            if (ttsAudio !== false) {
                ttsAudio.pause();
            }
        }
    }, [memberShip])

    return (


        <div className="innertab">
            <div className="alert alert-warning text-center">
                This voiceover would only be added to current slide
            </div>
            <div className="innertab-scroll alt">
                <div className="ai-voice-block">
                    <div className="voice-block-top">
                        <div className="row">
                            <div className="col-3">
                                <input className="form-control" type="text" name="slide" value={selectedSlideIndex + 1} readOnly />
                                <span className="optDesc text-light">SLIDE NO.</span>
                            </div>
                            <div className="col-4">
                                <select
                                    onChange={(e) => getLanguage(e)}
                                >
                                    <option disabled selected>Select Language</option>
                                    {
                                        pollyLanguages.data.length > 0 ?
                                            pollyLanguages.data.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.name}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <span className="optDesc text-light">LANGUAGE</span>
                            </div>
                            <div className="col-5">
                                <select onChange={(e) => getVoices(e)} className="cursor-pointer text-hover-effect">
                                    <option disabled selected>Select Voice</option>
                                    {
                                        pollyVoices.data.length > 0 ?
                                            pollyVoices.data.map((item, index) => {

                                                return (
                                                    <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <span className="optDesc text-light">VOICE</span>
                            </div>
                        </div>
                    </div>
                    <div className="voice-block-mid">
                        <textarea
                            placeholder="Add text here"
                            onChange={(e) => onEditTts(e)}
                            value={ttsText}
                        />
                        <div className="row">
                            <div className="voice-check-box  ">
                                <label htmlFor='addTtsAll' className='d-flex'>
                                    <input
                                        type="checkbox"
                                        id='addTtsAll'
                                        checked={addAllText}
                                        onChange={handleAddAllText} style={{ width: '40px', height: "19px", }}
                                    />
                                    <span className='align-self-center' style={{ fontSize: '13px' }}>Load all layers text from the slide</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="voice-block-bottom">
                        <div className="row">
                            <div className="col-lg-7">
                                {ttsStatus ?
                                    <button disabled={loader} className="demoLink btn-block mb-sm-2 justify-content-center" onClick={() => generateTts()} >
                                        <span className="mr-1">
                                            <i className="fas fa-file-upload" />
                                        </span>
                                        {loader ?
                                            <>
                                                Generating
                                                <i className="fa fa-spinner fa-spin mx-1" />
                                            </> : "Generate"}

                                    </button>
                                    :
                                    <button className="demoLink btn-block " onClick={() => playTts(generateData.url)} >
                                        <span className="mr-1">
                                            <i className={`fas ${currentTts === generateData.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                        </span>
                                        Preview
                                    </button>
                                }
                            </div>
                            <div className="col-lg-5" >
                                <button
                                    onClick={() => applyTts()}
                                    className={`${ttsStatus ? "disable-button" : ''} demoLink btn-block`}
                                    disabled={ttsStatus}

                                >
                                    <img src={iconSave} />{" "}
                                    Use
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                <TranslateText
                    selectedSlideIndex={selectedSlideIndex}
                    selectedSlide={selectedSlide}
                    setDataToRevert={setDataToRevert}
                />

            </div>
        </div>
    )
}

export default AiVoice