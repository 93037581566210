import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import iconUplod from '../../images/icon-upload.svg';
import iconWrite from '../../images/icon-write.svg';
import iconMic from '../../images/icon-mic.svg';
import iconRight from '../../images/icon-arrow.svg';
import modalCloseIcon from '../../images/modal-close.png';
import Alert from "../Alert"
import CreateCourseModal from "../createCourse/CreateCourseModal";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../actions/alert";
import BannerImg from "../../images/appbanner.jpg"
const DashboardHead = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const course = useSelector(state => state.course)
    const rebrandData = useSelector(state => state.rebrand.data);
    const [show, setShow] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showClose, setShowClose] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const [memberShip, setMemberShip] = useState([])

    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: 30,
        left: "-141px",
        fontSize: 10,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: 5,
        borderRadius: 5,
        course: "pointer"
    }



    const handleShow2 = () => {
        if (memberShip.length === 1 && memberShip[0] === "premium") {
            if (course.coursesAll.length >= 50) {
                dispatch(setAlert("You can create max courses of 50 only", "danger"))
            } else {
                setShow2(true);
            }
        } else {
            setShow2(true);
        }
    }
    const handelClose2 = () => {
        setShowPopup(false);
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])
    return (
        <>
            <Alert />
            <section className="hero-banner">
                <div className="hero-video">
                    <iframe src="https://player.vimeo.com/video/428018128?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                </div>
                <div className="hero-banner-txt">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="hero-banner-left">
                                    <h2>WELCOME TO</h2>
                                    <h3>{rebrandData ? rebrandData.name : "COURSEREEL AI"}</h3>
                                    <p>Start converting your voice-over or audio recordings into full content videos. Watch how easy it is for anyone to create great videos by converting audio or video recordings into a fresh new look using AI.</p>
                                    <a onClick={handleShow} className="demoLink"><i class="fa fa-play" aria-hidden="true"></i> Watch Demo Video</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hero-banner-right">
                                    {auth.user.is_client_account !== "1" ?
                                        <a onClick={handleShow2} className="bannerLink">
                                            <div className="bannerLink-left"><img src={iconWrite} />Create Course</div>
                                            <div className="bannerLink-right"><img src={iconRight} /></div>
                                        </a> : ""}
                                    <Link to="/courses" className="bannerLink">
                                        <div className="bannerLink-left"><img src={iconUplod} />View Course</div>
                                        <div className="bannerLink-right"><img src={iconRight} /></div>
                                    </Link>
                                    {auth.user.is_client_account !== "1" ?
                                        <Link to="/integration" className="bannerLink">
                                            <div className="bannerLink-left"><img src={iconMic} />Integrations</div>
                                            <div className="bannerLink-right"><img src={iconRight} /></div>
                                        </Link> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Modal className="VideoModal" show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="modalVidWrap">
                        <div className="modalVid">
                            <iframe src="https://player.vimeo.com/video/746081609?h=28c219f82c?loop=false&amp;autoplay=true&amp;muted=false&amp;gesture=media&amp;playsinline=true&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=false" allowfullscreen="" allow="autoplay,fullscreen,picture-in-picture" title="Player for CourseReel Intro" data-ready="true" tabindex="-1"></iframe>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className="VideoModal" show={showPopup} onHide={handelClose2} centered>
                <Modal.Body>
                    <div onClick={() => setShowClose(true)} className="vidClose" >
                        <div style={{ position: "relative" }}>
                            <img alt="" src={modalCloseIcon} />
                            {showClose ?
                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                        </div>

                    </div>
                    <div className="modalVid">
                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                        <div className="para_ai h-100">
                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                <p style={{
                                    fontSize: "55px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: "1.3",
                                    color: "#fff"
                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                            </a>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <CreateCourseModal
                show={show2}
                handleClose={handleClose2}
            />
        </>
    )
}

export default DashboardHead;