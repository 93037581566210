const initialState = false

export default function (state = initialState, action) {

    switch (action.type) {
        case "ADD_SLIDE_CHAPTER":
            state = action.payload
            return {
                ...state
            }
        case "ADD_SLIDES":
            let temp1 = [...action.payload]
            return {
                ...state,
                slides: temp1
            }

        case "NEW_SLIDE_ADD":
            let tempslide = [...state.slides]
            tempslide.push(action.payload)
            return {
                ...state,
                slides: tempslide
            }

        case "UNMOUNT_SLIDES":
            return {
                ...state,
                slides: action.payload
            }

        case "UPDATE_TEXT_FONTSTYLE":
            let slidesArrfFamily = [...state.slides]
            let textArrfFamily = [...slidesArrfFamily[action.payload.selectedSlideIndex].layers]
            textArrfFamily[action.payload.selectedTextIndex].style = {
                ...textArrfFamily[action.payload.selectedTextIndex].style,
                fontFamily: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfFamily
            }

        case "UPDATE_TEXT_FONTSIZE":
            let slidesArrfSize = [...state.slides]
            let textArrfSize = [...slidesArrfSize[action.payload.selectedSlideIndex].layers]
            textArrfSize[action.payload.selectedTextIndex].style = {
                ...textArrfSize[action.payload.selectedTextIndex].style,
                fontSize: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfSize
            }

        case "UPDATE_TEXT_FONTBOLD":
            let slidesArrfBold = [...state.slides]
            let textArrfBold = [...slidesArrfBold[action.payload.selectedSlideIndex].layers]
            textArrfBold[action.payload.selectedTextIndex].style = {
                ...textArrfBold[action.payload.selectedTextIndex].style,
                fontWeight: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfBold
            }

        case "UPDATE_TEXT_FONTITALIC":
            let slidesArrfItalic = [...state.slides]
            let textArrfItalic = [...slidesArrfItalic[action.payload.selectedSlideIndex].layers]
            textArrfItalic[action.payload.selectedTextIndex].style = {
                ...textArrfItalic[action.payload.selectedTextIndex].style,
                fontStyle: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfItalic
            }

        case "UPDATE_TEXT_FONTTRANSFORM":
            let slidesArrfTrans = [...state.slides]
            let textArrfTrans = [...slidesArrfTrans[action.payload.selectedSlideIndex].layers]
            textArrfTrans[action.payload.selectedTextIndex].style = {
                ...textArrfTrans[action.payload.selectedTextIndex].style,
                textTransform: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfTrans
            }

        case "UPDATE_TEXT_FONTDECO":
            let slidesArrfDeco = [...state.slides]
            let textArrfDeco = [...slidesArrfDeco[action.payload.selectedSlideIndex].layers]
            textArrfDeco[action.payload.selectedTextIndex].style = {
                ...textArrfDeco[action.payload.selectedTextIndex].style,
                textDecoration: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfDeco
            }

        case "UPDATE_TEXT_FONTALIGN":
            let slidesArrfAlign = [...state.slides]
            let textArrfAlign = [...slidesArrfAlign[action.payload.selectedSlideIndex].layers]
            textArrfAlign[action.payload.selectedTextIndex].style = {
                ...textArrfAlign[action.payload.selectedTextIndex].style,
                textAlign: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfAlign
            }

        case "UPDATE_TEXT_FONTLINEHEIGHT":
            let slidesArrfLheight = [...state.slides]
            let textArrfLheight = [...slidesArrfLheight[action.payload.selectedSlideIndex].layers]
            textArrfLheight[action.payload.selectedTextIndex].style = {
                ...textArrfLheight[action.payload.selectedTextIndex].style,
                lineHeight: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfLheight
            }

        case "UPDATE_TEXT_FONTOPACITY":
            let slidesArrfOpacity = [...state.slides]
            let textArrfOpacity = [...slidesArrfOpacity[action.payload.selectedSlideIndex].layers]
            textArrfOpacity[action.payload.selectedTextIndex].style = {
                ...textArrfOpacity[action.payload.selectedTextIndex].style,
                opacity: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfOpacity
            }


        case "UPDATE_TEXT_FONTCOLOR":
            let slidesArrfColor = [...state.slides]
            let textArrfColor = [...slidesArrfColor[action.payload.selectedSlideIndex].layers]
            textArrfColor[action.payload.selectedTextIndex].style = {
                ...textArrfColor[action.payload.selectedTextIndex].style,
                fontColor: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfColor
            }

        case "UPDATE_TEXT_FONTBGCOLOR":
            let slidesArrfBgColor = [...state.slides]
            let textArrfBgColor = [...slidesArrfBgColor[action.payload.selectedSlideIndex].layers]
            textArrfBgColor[action.payload.selectedTextIndex].style = {
                ...textArrfBgColor[action.payload.selectedTextIndex].style,
                backgroundColor: action.payload.data
            }
            return {
                ...state,
                slides: slidesArrfBgColor
            }

        case "REMOVE_TEXT_FONTBGCOLOR":
            let slidesArrfRBgColor = [...state.slides]
            let textArrfRBgColor = [...slidesArrfRBgColor[action.payload.selectedSlideIndex].layers]
            textArrfRBgColor[action.payload.selectedTextIndex].style = {
                ...textArrfRBgColor[action.payload.selectedTextIndex].style,
                backgroundColor: ""
            }
            return {
                ...state,
                slides: slidesArrfRBgColor
            }

        case "APPLY_TO_ALL":
            state.slides.forEach((slide) => {
                slide.layers.forEach((layer) => {
                    if (layer.type === "text") {
                        layer.style = action.payload
                    }
                })
            })
            return {
                ...state
            }

        // VoiceOver Reducers 

        case "UPDATE_TTS_TEXT":
            let slideArrTtsTextUpdate = [...state.slides]
            slideArrTtsTextUpdate[action.payload.index].voiceOver.meta.text = action.payload.text

            return {
                ...state,
                slides: slideArrTtsTextUpdate
            }


        case 'ADD_TTS_DATA':
            let slidesArrTts = [...state.slides]
            slidesArrTts[action.payload.selectedSlideIndex].voiceOver = {
                ...slidesArrTts[action.payload.selectedSlideIndex].voiceOver,
                enable: action.payload.enable,
                src: action.payload.meta.src,
                type: "mp3",
                meta: action.payload.meta.meta,
                duration: action.payload.meta.duration
            }
            return {
                ...state,
                slides: slidesArrTts
            }

        case 'REMOVE_TTSAUDIO_SLIDES':
            let slidesArrTtsRemove = [...state.slides]
            slidesArrTtsRemove[action.payload.selectedSlideIndex].voiceOver = {
                ...slidesArrTtsRemove[action.payload.selectedSlideIndex].voiceOver,
                enable: false,
                src: '',
                meta: action.payload.tts,
                duration: 0
            }
            return {
                ...state,
                slides: slidesArrTtsRemove
            }


        case "ADD_ALL_TEXT":
            let slidesArr = [...state.slides]
            slidesArr[action.payload.index].voiceOver = {
                ...slidesArr[action.payload.index].voiceOver,
                meta: {
                    ...slidesArr[action.payload.index].voiceOver.meta,
                    text: action.payload.text
                }
            }
            return {
                ...state,
                slides: slidesArr
            }

        // ========================================================================================


        case "UPDATE_TTS1_TEXT":
            let slidesArr3 = [...state.slides]
            slidesArr3[action.payload.index].voiceOver = {
                ...slidesArr3[action.payload.index].voiceOver,
                meta: {
                    ...slidesArr3[action.payload.index].voiceOver.meta,
                    text: action.payload.text
                }
            }
            return {
                ...state,
                slides: slidesArr3
            }

        case "UPDATE_GENERATED_TTS":
            let slidesArr4 = [...state.slides]
            slidesArr4[action.payload.index].voiceOver = {
                ...slidesArr4[action.payload.index].voiceOver,
                enable: true,
                type: "mp3",
                duration: action.payload.val.duration,
                src: action.payload.val.url,
                meta: {
                    ...slidesArr4[action.payload.index].voiceOver.meta,
                    languageId: action.payload.langData.lang,
                    voiceId: action.payload.langData.voice
                }
            }
            return {
                ...state,
                slides: slidesArr4
            }

        case "UPDATE_TEXT_TRANSLATE":
            let slidesArr5 = [...state.slides]
            slidesArr5[action.payload.index].voiceOver = {
                ...slidesArr5[action.payload.index].voiceOver,
                meta: {
                    ...slidesArr5[action.payload.index].voiceOver.meta,
                    translateText: action.payload.newText,

                }
            }
            return {
                ...state,
                slides: slidesArr5
            }

        case "REVERT_TRANSLATE":
            let slidesArr6 = [...state.slides]
            slidesArr6[action.payload].voiceOver = {
                ...slidesArr6[action.payload].voiceOver,
                meta: {
                    ...slidesArr6[action.payload].voiceOver.meta,
                    translateText: "",
                }
            }
            return {
                ...state,
                slides: slidesArr6
            }

        // Background Music  
        case 'ADD_BGAUDIO_DATA':
            return {
                ...state,
                audio: {
                    ...state.audio,
                    source: action.payload.url,
                    enable: true,
                    type: "mp3",
                    duration: action.payload.dur
                }
            }

        case 'REMOVE_BGAUDIO_SLIDES':
            return {
                ...state,
                audio: {
                    ...state.audio,
                    source: "",
                    enable: false,
                    type: ""
                }
            }


        // Background IMAGE/VIDEO

        case 'UPDATE_BG_IMAGE':
            let selectedSlideBg = [...state.slides]
            selectedSlideBg[action.payload.index].background = {
                ...selectedSlideBg[action.payload.index].background,
                type: "image",
                src: action.payload.file.url,
                poster: action.payload.file.thumbnail
            }
            return {
                ...state,
                slides: selectedSlideBg
            }

        case 'UPDATE_BG_VIDEO':
            let selectedSlideBg1 = [...state.slides]
            selectedSlideBg1[action.payload.index].background = {
                ...selectedSlideBg1[action.payload.index].background,
                type: "video",
                src: action.payload.file.url,
                poster: action.payload.file.thumbnail,
            }
            selectedSlideBg1[action.payload.index].bgDuration = action.payload.file.duration
            return {
                ...state,
                slides: selectedSlideBg1
            }

        case 'UPDATE_BG_COLOR':
            let selectedSlideBg2 = [...state.slides]
            selectedSlideBg2[action.payload.index].background = {
                ...selectedSlideBg2[action.payload.index].background,
                type: "color",
                src: action.payload.file,
                poster: action.payload.file,
                hex: action.payload.hex
            }
            return {
                ...state,
                slides: selectedSlideBg2
            }


        // LAYERS REDUCERS 

        case "UPDATE_LAYER":
            let slideArr = [...state.slides]
            let layerArr = [...slideArr[action.payload.selectedSlideIndex].layers]
            layerArr.forEach((curElem) => {
                curElem.isSelected = false
            })
            layerArr[action.payload.index].isSelected = true
            return {
                ...state,
                slides: slideArr
            }

        // TEXT LAYER 

        case "ADD_TEXT_LAYER":
            let selectedSlideLayer = [...state.slides]
            let selectedLayerLayer = [...selectedSlideLayer[action.payload.slideIndex].layers]
            selectedLayerLayer.forEach(layer => {
                layer.isSelected = false
            })
            selectedLayerLayer = [...selectedLayerLayer, action.payload.layer]
            selectedSlideLayer[action.payload.slideIndex].layers = selectedLayerLayer
            return {
                ...state,
                slides: selectedSlideLayer
            }

        case "UPDATE_LAYER_TEXT":
            let selectedSlideLayer1 = [...state.slides]
            let selectedLayerLayer1 = [...selectedSlideLayer1[action.payload.slideIndex].layers]
            selectedLayerLayer1[action.payload.layerIndex].text = action.payload.text
            return {
                ...state,
                slides: selectedSlideLayer1
            }
        //MEDIA LAYER

        case "ADD_MEDIA_LAYER":
            let selectedSlideData = [...state.slides]
            let layers = [...selectedSlideData[action.payload.index].layers]
            layers.forEach(layer => {
                layer.isSelected = false
            })
            layers = [...layers, action.payload.objData]
            selectedSlideData[action.payload.index].layers = layers
            return {
                ...state,
                slides: selectedSlideData
            }

        case "UPDATE_LAYER_IMAGE":
            let selectedSlideData1 = [...state.slides]
            let layers1 = { ...selectedSlideData1[action.payload.slideIndex].layers[action.payload.layerIndex] }
            layers1 = {
                ...layers1,
                src: action.payload.val,
                type: "image"
            }
            selectedSlideData1[action.payload.slideIndex].layers[action.payload.layerIndex] = layers1
            return {
                ...state,
                slides: selectedSlideData1
            }

        case "UPDATE_LAYER_VIDEO":
            let selectedSlideData2 = [...state.slides]
            let layers2 = { ...selectedSlideData2[action.payload.slideIndex].layers[action.payload.layerIndex] }
            layers2 = {
                ...layers2,
                src: action.payload.val.url,
                type: "video",
                size: {
                    ...layers2.size,
                    height: action.payload.val.height ? action.payload.val.height : 150,
                    width: action.payload.val.width ? action.payload.val.width : 280
                }
            }
            selectedSlideData2[action.payload.slideIndex].layers[action.payload.layerIndex] = layers2
            return {
                ...state,
                slides: selectedSlideData2
            }

        case "REMOVE_LAYER":
            let selectedSlide1 = [...state.slides]
            let layersAll = [...selectedSlide1[action.payload.selectedSlideIndex].layers]
            layersAll.splice(action.payload.layerIndex, 1)
            if (layersAll.length > 0) {
                layersAll[0].isSelected = true
            }
            selectedSlide1[action.payload.selectedSlideIndex].layers = layersAll
            return {
                ...state,
                slides: selectedSlide1
            }

        case "COPY_LAYER":
            let selectedSlide2 = [...state.slides]
            let layersAll2 = [...selectedSlide2[action.payload.slideIndex].layers]
            layersAll2[action.payload.layerIndex].isSelected = false
            layersAll2 = layersAll2.concat(action.payload.layerData)
            selectedSlide2[action.payload.slideIndex].layers = layersAll2
            return {
                ...state,
                slides: selectedSlide2
            }

        // VIdeo Tiles reducer

        case 'UPDATE_SLIDES_DATA':
            return {
                ...state,
                slides: action.payload
            }

        case 'UPDATE_SELECTED_TILES':
            let index = state.slides.findIndex(({ isSelected }) => isSelected === "1");
            state.slides[index].isSelected = "0";
            state.slides[action.payload].isSelected = "1";
            return {
                ...state
            }

        case "DELETE_TILE":
            let slidesVal = [...state.slides]
            slidesVal.splice(action.payload.deleteIndex, 1)
            if (action.payload.newIndex !== false) {
                slidesVal[action.payload.newIndex].isSelected = "1"
            }
            return {
                ...state,
                slides: slidesVal
            }

        // SETTING REDUCERS


        case 'UPDATE_STATUS_L&W':
            if (action.payload.type === 'logo') {
                return {
                    ...state,
                    logo: {
                        ...state.logo,
                        enable: action.payload.enable
                    }
                }
            }
            else if (action.payload.type === 'watermark') {
                return {
                    ...state,
                    watermark: {
                        ...state.watermark,
                        enable: action.payload.enable
                    }
                }
            }


            return {
                ...state
            }

        case 'ADD_LOGO':
            return {
                ...state,
                logo: {
                    ...state.logo,
                    src: action.payload.data
                }
            }

        case 'ADD_WATERMARK':
            return {
                ...state,
                watermark: {
                    ...state.watermark,
                    src: action.payload.data
                }
            }

        case 'REMOVE_LOGO_LAYER':
            return {
                ...state,
                logo: {
                    ...state.logo,
                    src: '',
                    postion: 'top-left'
                }
            }

        case 'REMOVE_WATERMARK_LAYER':
            return {
                ...state,
                watermark: {
                    ...state.watermark,
                    src: '',
                    postion: 'top-left'
                }
            }

        case 'UPDATE_LOGO_POSITION':
            return {
                ...state,
                logo: {
                    ...state.logo,
                    pos: action.payload.data,
                    positionX: action.payload.dimension.x,
                    positionY: action.payload.dimension.y

                }
            }

        case 'UPDATE_WATERMARK_POSITION':
            return {
                ...state,
                watermark: {
                    ...state.watermark,
                    pos: action.payload.data,
                    positionX: action.payload.dimension.x,
                    positionY: action.payload.dimension.y
                }
            }

        case 'UPDATE_SLIDE_DURATION':
            let data = [...state.slides]
            const selectedTile = data.findIndex(({ isSelected }) => isSelected === "1")
            data[selectedTile].duration = parseInt(action.payload)
            return {
                ...state,
                slides: data
            }

        case 'CHANGE_BG_VOLUME':
            return {
                ...state,
                audio: {
                    ...state.audio,
                    volume: parseInt(action.payload)
                }
            }

        case 'CHANGE_VOICE_VOLUME':
            let slidesArrVoice = [...state.slides]
            slidesArrVoice[action.payload.selectedSlideIndex].voiceOver = {
                ...slidesArrVoice[action.payload.selectedSlideIndex].voiceOver,
                volume: parseInt(action.payload.value)
            }
            return {
                ...state,
                slides: slidesArrVoice
            }


        // INTRO / OUTRO 

        case 'SET_INTRO_STATUS':
            return {
                ...state,
                intro: {
                    ...state.intro,
                    enable: action.payload
                }
            }
        case 'SET_OUTRO_STATUS':
            return {
                ...state,
                outro: {
                    ...state.outro,
                    enable: action.payload
                }
            }

        case 'REMOVE_INTRO':
            return {
                ...state,
                intro: {
                    ...state.intro,
                    src: '',
                    thumbnail: '',
                    name: ''
                }
            }

        case 'REMOVE_OUTRO':
            return {
                ...state,
                outro: {
                    ...state.outro,
                    src: '',
                    thumbnail: '',
                    name: ''
                }
            }

        case 'UPDATE_INTRO': {
            return {
                ...state,
                intro: {
                    ...state.intro,
                    src: action.payload.url,
                    thumbnail: action.payload.thumbData,
                    name: action.payload.nameData
                }
            }
        }
        case 'UPDATE_OUTRO': {
            return {
                ...state,
                outro: {
                    ...state.outro,
                    src: action.payload.url,
                    thumbnail: action.payload.thumbData,
                    name: action.payload.nameData
                }
            }
        }

        case "ADD_TRANSITION":
            let slidesData = [...state.slides]
            slidesData[action.payload.index].transition = {
                ...slidesData[action.payload.index].transition,
                simpleTransition: action.payload.name,
                enable: true
            }
            return {
                ...state,
                slides: slidesData
            }


        // LAYER EDITABLE

        case "RESIZE_LAYER":

            let slides1 = [...state.slides]
            let layersArr1 = [...slides1[action.payload.slideI].layers]
            layersArr1[action.payload.layerI].size = {
                ...layersArr1[action.payload.layerI].size,
                width: action.payload.w,
                height: action.payload.h
            }
            return {
                ...state,
                slides: slides1
            }

        case "REPOSITION_LAYER":

            let slides2 = [...state.slides]
            let layersArr2 = [...slides2[action.payload.slideI].layers]
            layersArr2[action.payload.layerI].position = {
                ...layersArr2[action.payload.layerI].position,
                left: action.payload.l,
                top: action.payload.t
            }
            return {
                ...state,
                slides: slides2
            }

        case "LAYER_DOWN":
            let slides3 = [...state.slides]
            let layersArr3 = [...slides3[action.payload.slideIndex].layers]
            let temp = layersArr3[action.payload.layerIndex]
            layersArr3[action.payload.layerIndex] = layersArr3[action.payload.layerIndex + 1]
            layersArr3[action.payload.layerIndex + 1] = temp
            slides3[action.payload.slideIndex].layers = layersArr3
            return {
                ...state,
                slides: slides3
            }

        case "LAYER_UP":
            let slides4 = [...state.slides]
            let layersArr4 = [...slides4[action.payload.slideIndex].layers]
            let temp11 = layersArr4[action.payload.layerIndex]
            layersArr4[action.payload.layerIndex] = layersArr4[action.payload.layerIndex - 1]
            layersArr4[action.payload.layerIndex - 1] = temp11
            slides4[action.payload.slideIndex].layers = layersArr4

            return {
                ...state,
                slides: slides4
            }

        default: {
            return state
        }
    }
}