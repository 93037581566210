import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'

const Background = (props) => {
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
    }, [props.background]);

    return (
        props.background.type === "video" ?
            <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0 ,}}>
                {loader ?
                    <div className="loader-center-temp1" style={props.mediaType === "bgImage" ? { background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)', width: "100%", height: "100%", position: 'absolute', zIndex: "2" } : {}}>
                        <i className="fa fa-spinner fa-spin mr-2" style={ { fontSize: "50px" }} />
                    </div> : ''}
                <ReactPlayer
                    url={props.background.src}
                    playing={true}
                    loop={true}
                    // className={` ${props.mediaType === "pImage" ? 'video-player' : ''}`}
                    onPlay={() => setLoader(false)}
                    muted
                    style={{ visibility: `${loader ? 'hidden' : 'visible'}` }}
                />
            </div> :
            props.background.type === "image" ?
                <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0,textAlign:"center" }}>
                    {loader ?
                        <div className="loader-center-temp1" style={props.mediaType === "bgImage" ? { background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)', width: "100%", height: "100%", position: 'absolute', zIndex: "2" } : {}} >
                            <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "50px" }} />
                        </div> : ''}
                    <img
                    className='editor-background-img'
                        src={props.background.src}
                        alt=''
                        onLoad={() => setLoader(false)}
                        style={{ visibility: `${loader ? 'hidden' : 'visible'}`, }}
                    />
                </div> :
                <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0, backgroundColor: `${props.background.src}` }}>
                </div>
    )
}

export default Background