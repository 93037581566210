import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { commonAxios } from '../../../global/CommonAxios';
import waveIcon from "../../../images/wave.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import AudioFiles from '../AudioFiles';

const Music = () => {
    const dispatch = useDispatch()
    const [currentUrl, setCurrentUrl] = useState('');
    const [loader, setLoader] = useState(false);
    const [audioData, setAudioData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [error, setError] = useState(false);
    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };
    // const selectedSlideIndex = slideData.findIndex(({ is_selected }) => is_selected === true)
    const [selectedAudio, setSelectedAudio] = useState(false)

    // useEffect(() => {
    //     if (video !== undefined && selectedSlideIndex !== -1) {
    //         // setSelectedAudio(video.data.slides[selectedSlideIndex].audio)
    //     }
    // }, [video, selectedSlideIndex])

    const fetchAudio = (page = 1) => {
        setLoader(true);
        let data = {
            search_type: 'music',
            page_number: page,
            keyword: ''
        }
        commonAxios("load-library-audio", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setAudioData(audioData.concat(res.data));
                    setPageCount(page);
                } else {
                    setError(res.data.message);
                }
                setLoader(false);
            }).catch(() => {
                setLoader(false);
                console.log(error)
            })
    }

    useEffect(() => {
        fetchAudio();
        return () => {
            setAudioData([]);
        }
    }, [])

    return (
        <div className="innertab">
            <div className="innertab-scroll alt" id="scrollableAudio">
                <div className="mediaList">
                    <ul>
                        <InfiniteScroll
                            dataLength={audioData.length} //This is important field to render the next data
                            next={() => fetchAudio(pageCount + 1)}
                            hasMore={true}
                            scrollableTarget="scrollableAudio"
                        >
                            <div className="media-ul-list audioList-single-music">
                                {audioData.length > 0 ?
                                    audioData.map((audio, index) => {
                                        return (
                                            <AudioFiles
                                                title={audio.title}
                                                // selectedSlideIndex={selectedSlideIndex}
                                                url={audio.url}
                                                className="audioList-single-music"
                                                selectedAudio={selectedAudio}
                                                setSelectedAudio={setSelectedAudio}
                                                currentUrl={currentUrl}
                                                setCurrentUrl={setCurrentUrl}
                                                key={index} 
                                                
                                                />
                                        )
                                    })
                                    : ''}
                            </div>
                            {
                                loader ?
                                    <div className="col-md-12">
                                        <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" style={{ color: '#6f42c1' }} /></h4>
                                    </div>
                                    : ''
                            }
                            {
                                error ?
                                    <div className="col-md-12">
                                        <h6 className="text-center">{error}</h6>
                                    </div>
                                    : ''
                            }
                        </InfiniteScroll>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Music