import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import iconSave from "../../images/icon-save.svg";
import iconrender from "../../images/icon-render.svg";
import iconStory from "../../images/icon-story.svg";
import iconText from "../../images/icon-text.svg";
import iconMedia from "../../images/icon-media.svg";
import iconAudio from "../../images/icon-audio.svg";
import iconSettings from "../../images/icon-settings.svg";
import iconCredits from "../../images/icon-credits.svg";
import iconMotion from "../../images/icon-motion.svg";
import iconTransition from "../../images/icon-transition.svg";
import logoImg from "../../images/IMG.png";
import { Tab, Nav } from "react-bootstrap";
import TitleBar from "../TitleBar";
import Audio from "./audio/Audio"
import Preview from "./preview/Preview";
import VideoTiles from "./videoTiles/videoTiles";
import Story from "./story/Story";
import Text from "./text/Text";
import Media from "./media/Media";
import BackgroundMedia from "./backgroundMedia/BackgroundMedia";
import Setting from "./setting/Setting";
import Credits from "./credits/Credits";
import Transition from "./transition/Transition";
import queryString from "query-string";
import { commonAxios } from "../../global/CommonAxios";
import { setAlert } from "../../actions/alert";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addSlideData, addSlides, removeSlides } from "../../actions/chapterAction";
import VoiceOver from "./voiceOver/VoiceOver";
import { addTextType } from "../../actions/courseAction";
import Alert from "../Alert";


const Editor = ({ location }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { courseId, chapterId } = queryString.parse(location.search)

  const auth = useSelector(state => state.auth)
  const chapterArr = useSelector(state => state.course.chapters)
  const chapter = useSelector(state => state.chapter)
  const [loader, setLoader] = useState(true)
  const [slideData, setSlideData] = useState([])
  const [loaderSave, setLoaderSave] = useState(false);
  const [loadRender, setLoadRender] = useState(false);
  const [memberShip, setMemberShip] = useState([])

  useEffect(() => {
    if (auth.user) {
      let data = auth.user.membership
      setMemberShip(data.split("__"))
    }
  }, [auth.user])

  const config = {
    headers: {
      "Content-Type": "application/json",
      'Authorization': localStorage.token
    },
  };


  const fetchSlide = () => {
    let data = {
      courseId: courseId,
      chapterId: chapterId
    }

    const arrayData = chapterArr.find(({ chapterId }) => chapterId === data.chapterId)
    dispatch(addSlideData(arrayData))
    commonAxios("slide-fetch", data, dispatch, config)
      .then((res) => {
        if (res.status) {
          dispatch(addSlides(res.data))
          setLoader(false)
        }
        else {
          history.push(`/write-chapters?id=${chapterId}`)
        }
      }).catch(err => {
        console.log(err)
      })
  }

  const saveAndRender = (mainData, type, shouldShow) => {
    let data = { ...mainData }
    if (shouldShow) {
      data.type = type
    } else {
      if (type === 'saved') {
        data.type = type
        setLoaderSave(true)
      }
      else if (type === 'render') {
        data.type = type
        setLoadRender(true)
      }
    }
    if (data.logo.src === "") {
      data = {
        ...data,
        logo: {
          ...data.logo,
          enable: false
        }
      }
    }
    if (data.watermark.src === "") {
      data = {
        ...data,
        watermark: {
          ...data.watermark,
          enable: false
        }
      }
    }
    if (data.intro.src === "") {
      data = {
        ...data,
        intro: {
          ...data.intro,
          enable: false
        }
      }
    }
    if (data.outro.src === "") {
      data = {
        ...data,
        outro: {
          ...data.outro,
          enable: false
        }
      }
    }
    commonAxios("saved-slides", data, dispatch, config)
      .then((res) => {
        if (res.status) {
          if (type === 'saved') {
            if (!shouldShow) {
              dispatch(setAlert(res.msg, 'success'))
            }
          }
          else if (type === 'render') {
            setLoadRender(false)
            dispatch(setAlert(res.msg, 'success'))
            history.push(`/chapters?courseId=${courseId}`)
          }
        }
        setLoadRender(false)
        setLoaderSave(false)
      }).catch((error) => {
        setLoadRender(false)
        setLoaderSave(false)
        console.log(error)
      })
  }


  const handleProject = (type, shouldShow) => {
    let data = { ...chapter }
    let totalTime = 0
    data.slides.forEach((curElem) => {
      totalTime += parseInt(curElem.duration)
    })
    totalTime = Math.floor(totalTime / 60)

    if (type === "render") {
      if (memberShip[0] === "premium" && memberShip.length === 1) {
        if (totalTime <= 15) {
          saveAndRender(data, type, shouldShow)
        }
        else {
          dispatch(setAlert("This chapter can not be rendered because the max limit of the rendered video is 15 min.", "danger"))

        }
      } else {
        if (totalTime <= 30) {
          saveAndRender(data, type, shouldShow)
        }
        else {
          dispatch(setAlert("This chapter can not be rendered because the max limit of the rendered video is 30 min.", "danger"))

        }
      }
    } else {
      saveAndRender(data, type, shouldShow)
    }
  }


  useEffect(() => {
    if (chapter) {
      if (chapter.slides) {
        setSlideData(chapter.slides)
      }
    }
  }, [chapter])

  useEffect(() => {
    fetchSlide()

    return () => {
      dispatch(removeSlides(false))
    }
  }, [])

  return (

    loader ?
      <div className="loader-sec">
        <div className="loader">
        </div>
      </div> :
      <>
        <TitleBar title="Editor" />
        <Navbar />
        <Alert />
        <section className="siteWrap">
          <div className="editorWrap">
            <Tab.Container id="left-tabs-example" defaultActiveKey="story">

              <div className="editor-left">
                <div>
                  <Tab.Content>

                    <Tab.Pane eventKey="story">
                      <Story
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="text">
                      <Text
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="bgMedia">
                      <BackgroundMedia
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="media">
                      <Media
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="voiceOver">
                      <VoiceOver
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="audio">
                      <Audio
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="settings">
                      <Setting
                        slideData={slideData}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="credits">
                      <Credits
                        chapterId={chapterId}
                      />
                    </Tab.Pane>

                    <Tab.Pane eventKey="transition">
                      <Transition
                        slideData={slideData}
                      />
                    </Tab.Pane>

                  </Tab.Content>
                </div>
              </div>

              <div className="editor-right">
                <div className="campaignTop">
                  <div className="campaignBar">
                    <button
                      className="demoLink cursor-pointer"
                      onClick={() => handleProject('saved')}
                    >
                      {loaderSave ?
                        <>
                          Saving
                          <i className="fa fa-spinner fa-spin mx-1" />
                        </> :
                        <>
                          Save  <img className="mx-1" src={iconSave} alt='save-icon' />
                        </>
                      }

                    </button>
                    <button
                      className="demoLink cursor-poninter"
                      onClick={() => handleProject('render')}
                    >
                      {loadRender ?
                        <>
                          Rendering
                          <i className="fa fa-spinner fa-spin mx-1" />

                        </> :
                        <>
                          Render
                          <img src={iconrender} alt='render-icon' className="mx-1" />

                        </>
                      }
                    </button>
                  </div>
                  <div className="campaignNav">
                    <Nav variant="pills">
                      <Nav.Item>
                        <Nav.Link eventKey="story">
                          <div>
                            <span><img src={iconStory} /></span>
                            <span>Story</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="text">
                          <div>
                            <span><img src={iconText} /></span>
                            <span>Text</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="bgMedia">
                          <div>
                            <span><img src={iconMedia} /></span>
                            <span>Background</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="media">
                          <div>
                            <span><img src={iconMedia} /></span>
                            <span>Media</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="voiceOver">
                          <div>
                            <span><img src={iconAudio} /></span>
                            <span>Voiceover</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="audio">
                          <div>
                            <span><img src={iconAudio} /></span>
                            <span>Music</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="transition">
                          <div>
                            <span><img src={iconTransition} /></span>
                            <span>Transition</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="settings">
                          <div>
                            <span><img src={iconSettings} /></span>
                            <span>Settings</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="credits">
                          <div>
                            <span><img src={iconCredits} /></span>
                            <span>Credits</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>


                {/* ------------ Preview --------- */}
                <Preview
                  bgData={chapter.audio}
                  slideData={slideData}
                  logo={chapter.logo}
                  watermark={chapter.watermark}
                />
              </div>
            </Tab.Container>
          </div>
        </section>

        <div className="editorReel">
          <VideoTiles
            slideData={slideData}
            handleProject={handleProject}
          />
        </div>
      </>
  )
}

export default Editor;