const initialState = {
    courseId: "",
    created: "",
    modified: "",
    projectName: "",
    status: "",
    thumbnail: "",
    topicName: null,
    chapters: [],
    coursesAll: false
}

export default function (state = initialState, action) {
    switch (action.type) {

        case "ADD_COURSE":
            return {
                ...state,
                courseId: action.payload.courseId,
                created: action.payload.created,
                modified: action.payload.modified,
                projectName: action.payload.projectName,
                status: action.payload.status,
                thumbnail: action.payload.thumbnail,
                topicName: action.payload.topicName,
                topicLimit: action.payload.topicLimit,
                chapterLimit: action.payload.chapterLimit,
                articleLimit: action.payload.articleLimit
            }

        case "ADD_TOPIC":
            return {
                ...state,
                topicName: action.payload
            }

        case "UPDATE_COURSE_NAME":
            return {
                ...state,
                projectName: action.payload
            }

        case "ADD_CHAPTERS":
            return {
                ...state,
                chapters: action.payload
            }

        case "ADD_TEXT_DATA":
            let chapterArray = [...state.chapters]
            let index = chapterArray.findIndex(({ chapterId }) => chapterId === action.payload)
            chapterArray[index].createdFrom = {
                ...chapterArray[index].createdFrom,
                source: "",
                enable: true,
                type: "text"
            }
            return {
                ...state,
                chapters: chapterArray
            }

        case "ADD_TRANSCRIBE_DATA":
            let chapterArray1 = [...state.chapters]
            let index1 = chapterArray1.findIndex(({ chapterId }) => chapterId === action.payload.chapterId)
            chapterArray1[index1].createdFrom = {
                ...chapterArray1[index1].createdFrom,
                source: action.payload.val.s3_file_uri,
                transcribeId: "",
                enable: true,
                type: action.payload.type === "video/mp4" ? "video" : "audio"
            }
            return {
                ...state,
                chapters: chapterArray1
            }

        case "UPDATE_TEXT":
            let chapterArray3 = [...state.chapters]
            let index3 = chapterArray3.findIndex(({ chapterId }) => chapterId === action.payload.id)
            chapterArray3[index3].createdFrom = {
                ...chapterArray3[index3].createdFrom,
                source: action.payload.text,
                text: action.payload.text
            }
            return {
                ...state,
                chapters: chapterArray3
            }

        case "UPDATE_TEXT_IF_FALSE":
            let chapterArray4 = [...state.chapters]
            let index4 = chapterArray4.findIndex(({ chapterId }) => chapterId === action.payload.id)
            chapterArray4[index4].createdFrom = {
                ...chapterArray4[index4].createdFrom,
                source: action.payload.text,
                type: "text",
                enable: true,
                text: action.payload.text
            }
            return {
                ...state,
                chapters: chapterArray4
            }

        case "ADD_ALL_COURSE_ID":
            return {
                ...state,
                coursesAll: action.payload
            }

        case "UPDATE_INTRO_COURSE":
            let chapterArray5 = [...state.chapters]
            chapterArray5[action.payload.cIndex].intro = {
                ...chapterArray5[action.payload.cIndex].intro,
                enable: true,
                src: action.payload.data.url,
                thumbnail: action.payload.data.thumbData,
                name: action.payload.data.nameData
            }
            return {
                ...state,
                chapters: chapterArray5
            }

        case "UPDATE_OUTRO_COURSE":
            let chapterArray6 = [...state.chapters]
            chapterArray6[action.payload.cIndex].outro = {
                ...chapterArray6[action.payload.cIndex].outro,
                enable: true,
                src: action.payload.data.url,
                thumbnail: action.payload.data.thumbData,
                name: action.payload.data.nameData
            }
            return {
                ...state,
                chapters: chapterArray6
            }
        default:
            {
                return state
            }
    }
}