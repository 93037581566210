import React from 'react'

const Logo = (props) => {
    const myStyle = {
        height: props.logo.height,
        width: props.logo.width
    }
    return (

        props.logo.src !== "" ?
            <div className={`logo-${props.logo.pos} logo-style `}>
                <img
                    src={props.logo.src}
                    alt="logo"
                    style={myStyle} />
            </div>
            : ''
    )
}

export default Logo