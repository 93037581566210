import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Alert from "../Alert";
import {updateName} from "../../actions/authAction";

const ProfileInfo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);

    const [userName, setUserName] = useState({
        name: auth.user.name,
        email: auth.user.email
    })

    const onInputChange = (e) => {
        setUserName({...userName, name: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(updateName(userName, setLoader));
    }

    useEffect(()=>{
        setUserName({name: auth.user.name, email: auth.user.email})
    },[auth.user])

    return(
        <>
            <Alert/>

            <div className="profileBlock">
              <h2 className="text-center">Personal Information</h2>
              <form method="post" onSubmit={(e)=>onFormSubmit(e)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group form-group-alt">
                                        <label className="form-text text-muted">Name</label>
                                        <input type="text" className="form-control" placeholder="Stephanie"
                                               required name="name" value={userName.name}
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-md-6">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <small className="form-text text-muted">Last Name</small>*/}
                                {/*        <input type="text" className="form-control" placeholder="Carley" />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-md-6">
                                    <div className="form-group form-group-alt">
                                        <label className="form-text text-muted">Your Email</label>
                                        <input type="text" className="form-control"
                                               placeholder="example@gmail.com"
                                               readOnly value={userName.email}
                                        />
                                    </div>
                                </div>
                            </div>

                            <button type="submit" className="fbtn btn-block demoLink"> { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update</button>

                        </form>
            </div>
        </>
    )
}

export default ProfileInfo;