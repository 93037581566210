import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

import TitleBar from "../TitleBar";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../actions/alert";
import { commonAxios } from "../../global/CommonAxios";
import Alert from "../Alert"
import { BsPlusCircle, BsTrash } from "react-icons/bs";
import { IoReloadSharp } from "react-icons/io5";
import { addCourse } from "../../actions/courseAction";
import CourseHeader from "./CourseHeader";


const CreateChapter = () => {
    const auth = useSelector(state => state.auth)
    const course = useSelector(state => state.course)
    const [limit, setLimit] = useState(2)
    const [disable, setDisable] = useState(false)
    const [suggestion, setSuggestion] = useState([])
    const [editCour, setEditCour] = useState({
        status: false,
        text: ""
    })
    const [loader, setLoader] = useState({
        save: false,
        ai: false,
    })
    const dispatch = useDispatch()
    const history = useHistory()
    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };
    const [fieldArr, setFieldArr] = useState([{
        text: "",
        button: "ADD",
        indexVal: -1
    }])


    const handleSubmit = (e) => {
        e.preventDefault()
        setDisable(true)
        let array = []
        fieldArr.forEach((curElem) => {
            let obj = {
                name: curElem.text
            }
            array.push(obj)
        })
        let data = {
            courseId: course.courseId,
            chapters: array
        }
        setLoader({
            ...loader,
            save: true
        })
        commonAxios("chapter-create", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    history.push(`/chapters?courseId=${course.courseId}`)
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setLoader({
                    ...loader,
                    save: false
                })
            }).catch(err => {
                setLoader({
                    ...loader,
                    save: false
                })
                console.log(err)
            })

    }

    const fetchCourse = (id) => {
        let data = { "courseId": id }
        commonAxios("course-fetch", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(addCourse(res.data))

                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }).catch(err => console.log(err))
    }

    const getAiData = (topicName, buttonCall) => {
        let data
        if (buttonCall) {
            data = {
                text: topicName,
                type: "chapter",
                limit: parseInt(course.chapterLimit) + 1,
                courseId: course.courseId
            }
        }
        else {
            data = {
                text: topicName,
                type: "chapter",
                limit: 0,
                courseId: course.courseId
            }
        }

        setLoader({
            ...loader,
            ai: true
        })
        commonAxios("openai-call", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setSuggestion(res.data)
                    setLimit(auth.user.chapter_limit - data.limit)
                    fetchCourse(course.courseId)
                }
                else {
                    dispatch(setAlert(res.msg))
                }
                setLoader({
                    ...loader,
                    ai: false
                })
            }).catch((error) => {
                setLoader({
                    ...loader,
                    ai: false
                })
                console.log(error)
            })
    }

    const handleAdd = () => {
        let arr = [...fieldArr]
        arr.push({ text: "", button: "DELETE", indexVal: -1 })
        setFieldArr(arr)
    }

    const handleDelete = (index) => {
        const arr = [...fieldArr]
        arr.splice(index, 1)
        setFieldArr(arr)
    }

    const handleChange = (text, index) => {
        let arr = [...fieldArr]
        arr[index].text = text
        setFieldArr(arr)
    }

    const handleCopy = (str, index) => {
        let text = str
        const findIndex = fieldArr.findIndex(({ text }) => text === "")
        if (findIndex === -1) {
            let arr = [...fieldArr]
            arr.push({ text: text, button: "DELETE", indexVal: index })
            setFieldArr(arr)
        } else {
            let arr = [...fieldArr]
            arr[findIndex].text = text
            arr[findIndex].indexVal = index
            setFieldArr(arr)
        }
    }


    useEffect(() => {
        let data = {
            courseId: course.courseId,
            type: "chapter",
        }
        commonAxios("course-ai-data", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    if (res.data.length > 0) {
                        setSuggestion(res.data)
                    } else {
                        getAiData(course.topicName)
                    }
                }
            }).catch((err) => {
                console.log(err)
            })
    }, [])



    useEffect(() => {
        setLimit(auth.user.chapter_limit - course.chapterLimit)
    }, [course.chapterLimit])

    return (
        <>
            <TitleBar title="Create Course" />
            <Navbar />
            <Alert />
            <section className="siteWrap">
                <div className="create-course-top">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <h2>
                                    <CourseHeader
                                        courseName={course.projectName}
                                        courseId={course.courseId}
                                        topicName={course.topicName}
                                        config={config}
                                    />
                                    | {course.topicName}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="create-course-mid alt">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6">
                                <div className="course-mid-main">
                                    <h2 className="text-center">Chapters</h2>
                                </div>
                            </div>
                            <div className="col-xl-12">

                                <form className="inpWithBtn" onSubmit={handleSubmit}>
                                    <div className="row justify-content-center">
                                        {fieldArr.length > 0 ?
                                            fieldArr.map((curElem, index) => {
                                                return (
                                                    <>
                                                        <div className=" d-flex justify-content-center align-items-center text-light mt-2" key={index}>
                                                            <input
                                                                className="courseInp"
                                                                type="text"
                                                                required
                                                                name="projectName"
                                                                value={curElem.text}
                                                                onChange={(e) => handleChange(e.target.value, index)}
                                                                style={{ maxWidth: "900px" }}
                                                            />

                                                            <div className=" text-md-start ">
                                                                {curElem.button === "ADD" ?
                                                                    <BsPlusCircle
                                                                        style={{
                                                                            color: "#CE88FF",
                                                                            fontSize: "32px",
                                                                            marginLeft: '10px',
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={handleAdd}
                                                                        title="Add Tab"
                                                                    /> :
                                                                    <BsTrash
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            color: "#CE88FF",
                                                                            fontSize: "32px",
                                                                            marginLeft: '10px',
                                                                        }}
                                                                        title="Delete Tab"
                                                                        onClick={() => handleDelete(index)}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }) : ''}



                                        <div className="col-xl-12">
                                            <div className=" text-md-end text-sm-center text-center mt-4">
                                                <button
                                                    type="submit"
                                                    className="demoLink-chapter"
                                                    disabled={disable}

                                                >{loader.save ? <> <span style={{ fontWeight: 'bold' }}>Saving</span> <i className="fa fa-spinner fa-spin mr-2" /> </> : "Save"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form >
                                <div className="col-md-12 text-center mt-4">
                                    <div className="create-or-line"> <span>{loader.ai ? <> <a style={{ color: '#A600F8' }}>Recommending Chapters</a>  </> : "Recommend Chapters"}</span>  </div>
                                </div>

                                <div className="courseBtn text-center mt-4">
                                    {suggestion.length === 0 ? "" :
                                        <button
                                            type="button"
                                            className="demoLink"
                                            onClick={() => getAiData(course.topicName, true)}
                                            disabled={limit === 0 ? true : false}
                                            style={limit === 0 ? { background: "gray" } : {}}
                                        >
                                            {loader.ai ?
                                                <i className="fa fa-spinner fa-spin mx-2" /> :
                                                <>
                                                    <IoReloadSharp
                                                        style={{ fontSize: "35px", transform: " rotate(172deg)" }}
                                                    />
                                                    <a style={{ position: "relative", top: "1px", left: "-20px", fontSize: "13px" }}>{limit}</a>
                                                </>
                                            }
                                            <span>{loader.ai ? "Loading More Recommendations" : "Load More Recommendations"} </span>

                                        </button>
                                    }
                                </div>
                            </div >
                            <div className="recomnd">
                                <ul>
                                    {suggestion.length > 0 ?
                                        suggestion.map((curElem, index) => {
                                            let str = curElem.output_string.replace(/^[1-9][0-9]*\.\s|^[1-9][0-9]*\)\s/g, '')
                                            let status = fieldArr.findIndex(({ indexVal }) => indexVal === index)
                                            return (
                                                <li key={index}>
                                                    <div className="recomnd-single d-flex justify-content-between align-items-center">
                                                        <p
                                                            style={{
                                                                textAlign: "left",
                                                                paddingRight: '23px',
                                                                color: '#fff',
                                                                margin: "auto 0"
                                                            }}
                                                        >{str}</p>
                                                        {status !== -1 ?
                                                            <a className="demoLink mt-0 " style={{ color: '#fff', background: "gray" }}>Added</a>
                                                            :
                                                            <a className="demoLink mt-0 " onClick={() => handleCopy(str, index, curElem.id, curElem.isSelected)} style={{ color: '#fff' }}>Add This</a>}
                                                    </div>
                                                </li>
                                            )
                                        })
                                        : ''}
                                    {
                                        loader.ai ?
                                            <div className="text-center">
                                                <i className="fa fa-spinner fa-spin mr-2" style={{ color: '#ce88ff', fontSize: '25px' }} />
                                            </div>
                                            : ""
                                    }
                                </ul>
                            </div>

                        </div >
                    </div >
                </div >

            </section >

            <Footer />
        </>
    )
}

export default CreateChapter;