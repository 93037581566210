import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addTransition } from '../../../actions/chapterAction'

const TransCard = ({ curElem, slideData }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        active: false,
        loader: false
    })
    const selectdSlideIndex = slideData.findIndex(({ isSelected }) => isSelected === "1")


    const handleTransition = (name) => {
        dispatch(addTransition(name, selectdSlideIndex))
    }
    const handleActive = () => {
        setState({
            active: true,
            loader: true
        })
    }
    const handleDeavtive = () => {
        setState({
            active: false,
            loader: false
        })
    }

    return (
        <div className="transition-single mt-2"
            onClick={() => handleTransition(curElem.name)}
            onMouseEnter={() => handleActive()}
            onMouseOut={() => handleDeavtive()}
        >
            <img
                className="transition-img"
                src={state.active ? curElem.preview : curElem.thumbnail}
                onLoad={() => setState({
                    ...state,
                    loader: false
                })}
            />
            <div className="transition-txt text-light text-capitalize" style={{ fontSize: "15px" }}>{state.loader ? <i className="fa fa-spinner fa-spin"  style={{color : "#d10fd1", fontSize : 25}}/> : curElem.name}</div>
            {
                curElem.name === slideData[selectdSlideIndex].transition.simpleTransition ?
                    <div className="slide-active">
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                    : ''
            }
        </div>
    )
}

export default TransCard