import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { addCourse } from '../../actions/courseAction';
import { commonAxios } from '../../global/CommonAxios';
import modalCloseIcon from '../../images/modal-close.png';

const CreateCourseModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [courseName, setCourseName] = useState("")
    const [loader, setLoader] = useState(false)
    const config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = { "projectName": courseName }

        setLoader(true)
        commonAxios("course-create", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                   handleFetch(res.data.courseId)
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                    setLoader(false)
                }
            }).catch(err => {
                setLoader(false)
                console.log(err)
            })

    }

    const handleFetch = (id) => {
        let data = { "courseId": id }
        commonAxios("course-fetch", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setLoader(false)
                    dispatch(addCourse(res.data))
                    history.push("/create-topic")
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
            }).catch(err => console.log(err))
    }

    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <label>Enter the Project Name</label>
                        <input
                            type="text"
                            className="inpField-inp"
                            placeholder="Project Name"
                            onChange={(e) => setCourseName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn-block inpBtn mt-3 create-modal-btn">Next {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateCourseModal