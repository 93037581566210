import React, { useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";
import SweetAlert from "react-bootstrap-sweetalert";
import PublishModal from "./PublishModal";


const MergeCard = ({ item, fetchMerge }) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const [loadEdit, setLoadEdit] = useState(false)
    const [showModel, setShowModel] = useState(false);

    const [buttonText, setButtonText] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [progressVideo, setProgressVideo] = useState(false);

    const openDeletePopUp = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }

    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-merge`,
            data: { id: deleteId, user_id: auth.user.id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchMerge();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }

    return (
        <>
            <Alert />
            <div className="col-lg-3 col-md-6 mb-4 mt-5">
                <div className="video-card ">
                    <div className="video-img ">
                        {
                            item.output_video !== "" ?
                                <img src={item?.preview_image} alt="" className="img-fluid"
                                    style={{
                                        borderRadius: "10px"
                                    }}
                                /> :
                                <div className="video-card-wrapper-1" style={{height : "192px"}}>
                                </div>

                        }
                        {
                            item.output_video === "" ?
                                <div className="render-overlay">{item.status}</div>
                                : ''
                        }

                        <div className="ClipsReel-overlay">

                            <p className="mt-4 text-center text-white mb-3">{item.created}</p>

                            <ul className="list-inline m-0 px-2 d-flex justify-content-around mt-1">
                                {
                                    item.output_video !== "" ?
                                        <li className="text-center">
                                            <a href={`${item.output_video}`} style={{ color: "#fff" }} className="cursor-pointer">
                                                <span><i className="far fa-arrow-alt-circle-down"></i></span>
                                                Download
                                            </a>
                                        </li>
                                        : ''
                                }
                                {
                                    auth.user.is_client_account == "0" ?
                                        <>
                                            {
                                                item.output_video !== "" ?
                                                    <li className="text-center">
                                                        <a onClick={() => setShowModel(true)} style={{ color: "#fff" }} className="cursor-pointer">
                                                            <span><i className="fas fa-cloud-upload-alt"></i></span>
                                                            Publish
                                                        </a>
                                                    </li>
                                                    : ''
                                            }
                                            <li className="text-center">
                                                <a style={{ color: "#fff" }} className="cursor-pointer" onClick={() => openDeletePopUp(item.id)}>
                                                    <span><i className="far fa-trash-alt"></i></span>
                                                    Delete
                                                </a>
                                            </li>
                                        </>
                                        : ''
                                }
                            </ul>
                        </div>
                    </div>


                    <div className="video-title">
                        <div className="row">
                            <div className="col-md-12 col-12 d-flex align-items-center">
                                <div className="video-name text-center text-white">
                                    <p className="m-0">{item.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <PublishModal
                    showModel={showModel}
                    setShowModel={setShowModel}
                    curElem={item}
                />

                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText={buttonText}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="light"
                    title="Are you sure?"
                    onConfirm={() => onConfirm()}
                    onCancel={() => setShowDelete(false)}
                    focusCancelBtn
                    show={showDelete}
                >
                    You want to delete this campaign
                </SweetAlert>
            </div>


        </>
    )
}

export default MergeCard;