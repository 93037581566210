import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../actions/alert';
import { addTransition } from '../../../actions/chapterAction';
import { commonAxios } from '../../../global/CommonAxios';
import TransCard from './TransCard';

const Transition = ({ slideData }) => {

    const dispatch = useDispatch()
    const [data, setData] = useState([])

    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };
    const fetchTransition = () => {
        commonAxios("fetch-transition", {}, dispatch, config)
            .then((res) => {
                if (res.status) {
                    setData(res.data)
                }
            }).catch((error) => {
                console.log(error)
            })
    }



    useEffect(() => {
        fetchTransition()
    }, [])
    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <h2>Transition</h2>
                    <div className="add-block-main">
                        <div className="motionlist">
                            <ul>
                                {data.length > 0 ?
                                    data.map((curElem, index) => {
                                        if (curElem.image !== "") {
                                            return (
                                                <li key={index} className="col-6" >
                                                    <TransCard
                                                        curElem={curElem}
                                                        slideData={slideData}
                                                    />

                                                </li>
                                            )
                                        }
                                    }) : ''}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Transition

