import React, { useEffect, useState } from 'react'
import { Rnd } from "react-rnd";

const VideoLayer = (props) => {
    const [myStyle, setMyStyle] = useState({
        opacity: parseInt(props.layer.style.opacity),
        left: props.layer.position.left,
        top: props.layer.position.top,
        width: props.layer.size.width,
        height: props.layer.size.height,
        position: "absolute",
    })

    useEffect(() => {
        const obj = {
            opacity: parseInt(props.layer.style.opacity),
            left: props.layer.position.left,
            top: props.layer.position.top,
            width: props.layer.size.width,
            height: props.layer.size.height,
            position: "absolute",
        }
        setMyStyle(obj)
    }, [props.layer.style, props.layer.position, props.layer.size])

    return (
        <>
            <Rnd
                lockAspectRatio={true}
                style={{ overflow: "hidden", textAlign: "center", padding: 0 }}
                enableUserSelectHack={true}
                enableResizing={props.layer.isSelected ? true : false}
                disableDragging={props.layer.isSelected ? false : true}
                onClick={() => props.handleSelectLayer(props.layerIndex)}
                default={{ x: 0, y: 0, height: "auto", width: "auto" }}
                size={{
                    height: myStyle.height ? myStyle.height : 150,
                    width: myStyle.width ? myStyle.width : 280
                }}
                position={{ x: myStyle.left, y: myStyle.top }}
                onDragStop={(e, d) => {
                    setMyStyle({ ...myStyle, left: d.x, top: d.y })
                    props.handleRePosition(d.y, d.x, props.layerIndex)
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setMyStyle({ ...myStyle, width: ref.style.width, height: ref.style.height });
                    props.handleResize(ref.style.width, ref.style.height, props.layerIndex)
                }}
                bounds=".canvasEditor-main"
                className={props.layer.isSelected ? 'layer-border' : ''}
            >
                <video
                    src={props.layer.src}
                    autoPlay={true}
                    muted
                    loop={true}
                    style={{
                        // width: myStyle.width,
                        height: myStyle.height
                    }}

                    onClick={() => props.handleSelectLayer(props.layerIndex)}
                />
            </Rnd>
        </>
    )
}

export default VideoLayer