import React, { useState, useEffect } from 'react'
import videoThumb from '../../images/fav-new.png';
import { Link } from "react-router-dom";
import { commonAxios } from '../../global/CommonAxios';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../actions/alert';
import SweetAlert from 'react-bootstrap-sweetalert';
import PublishModal from '../project/PublishModal';

let intCheck, rendCheck
const ChapterCards = ({ curElem, fetchChapters, chapterData, fileType }) => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth);
    const [state, setState] = useState(0)
    const [button, setButton] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [showModel, setShowModel] = useState(false)
    const [checkData, setCheckData] = useState({
        renderProgress: 0,
        renderStatus: ""
    })

    let config = {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.token
        },
    };



    const handleSaveTranscribe = (data) => {
        clearInterval(intCheck)
        let obj = {
            courseId: curElem.courseId,
            chapterId: curElem.chapterId,
            text: data.transcribeText,
            slideBreak: "separator",
            buttonType: "save",
            createdFrom: {
                enable: true,
                type: fileType === "video/mp4" ? "video" : "audio",
                source: data.transcript_uri,
                transcribeId: data.transcribeId,
                text: data.transcribeText
            }
        }
        commonAxios("slide-create", obj, dispatch, config)
            .then((res) => {
                if (res.status) {
                    fetchChapters()
                }
            }).catch(err => {
                console.log(err)
            })
    }
    const checkTranscribe = () => {
        let data = {
            courseId: curElem.courseId,
            chapterId: curElem.chapterId
        }
        commonAxios("check-transcribe", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    handleSaveTranscribe(res.data)
                    setState(8)
                }
                else {
                    if (res.msg === "Transcription failed") {
                        setState(7)
                        clearInterval(intCheck)
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const checkRendering = () => {
        let data = {
            courseId: curElem.courseId,
            chapterId: curElem.chapterId
        }
        commonAxios("chapter-status", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    if (res.data[0].status === "4") {
                        setState(+res.data[0].status)
                        setCheckData({
                            ...checkData,
                            renderProgress: res.data[0].renderProgress,
                            renderStatus: res.data[0].renderStatus
                        })
                    }
                    else if (res.data[0].status === "6") {
                        clearInterval(rendCheck)
                        fetchChapters()
                    }
                    else if (res.data[0].status === "3") {
                        clearInterval(rendCheck)
                    }
                    else if (res.data[0].status === "5" || res.data[0].status === "10" || res.data[0].status === "11") {
                        setState(+res.data[0].status)
                    }
                } else {
                    clearInterval(rendCheck)
                }
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleDeleteAlert = () => {
        setShowDelete(true)
    }

    const handlePublish = () => {
        setShowModel(true)
    }
    const onConfirm = () => {
        let data = {
            type: "chapters",
            id: curElem.chapterId
        }
        setButton("Deleting...")
        commonAxios("delete-record", data, dispatch, config)
            .then((res) => {
                if (res.status) {
                    dispatch(setAlert(res.msg, "success"))
                    fetchChapters()
                } else {
                    dispatch(setAlert(res.msg, "danger"))
                }
                setButton("Delete")
                setShowDelete(false)
            }).catch((err) => {
                setShowDelete(false)
                setButton("Delete")
                console.log(err)
            })
    }

    useEffect(() => {
        setState(parseInt(curElem.status))
    }, [chapterData])

    useEffect(() => {
        if (state === 9) {
            intCheck = setInterval(() => {
                checkTranscribe()
            }, 5000)
        } else if (state === 4 || state === 5 || state === 10 || state === 11) {
            rendCheck = setInterval(() => {
                checkRendering()
            }, 5000)
        }

        return () => {
            clearInterval(rendCheck)
            clearInterval(intCheck)
        }
    }, [state])


    console.log(state)
    return (

        <>
            <div className="videoProject-single">
                <div className="videoProject-single-top">
                    <div className={state === 9 ? "video-card-wrapper" : state === 8 ? "video-card-wrapper-1" : state === 7 ? "video-card-wrapper-2" : "video-card-wrapper-1"}>
                        <div className="video-card-content">
                            {state === 4 ?
                                <div className="row" style={{ width: "100%" }}>
                                    <div className=" col-7 progress-content text-white ">
                                        <span style={{ fontSize: '14px', color: "#fff" }}>Your Progress</span>
                                        <h5 style={{ fontSize: '14px', color: "#fff " }}>{checkData.renderStatus}</h5>
                                    </div>
                                    <div className="col-5 ">
                                        <div className="progress-status text-white float-end">
                                            <span style={{ fontSize: '17px', color: "#fff", fontWeight: '600' }}>{checkData.renderProgress}%</span>
                                            <h5 style={{ fontSize: '14px', color: "#fff " }}>Completed</h5>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <div className="progress-bar-status">
                                            <div className="progress " style={{ backgroundColor: ' rgb(0 0 0 / 47%)' }}>
                                                <div className=" progress-bar-render progress-bar-striped bg-danger progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100%" style={{ width: `${parseInt(checkData.renderProgress)}%` }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :

                                state === 6 ?
                                    <img src={curElem.thumbnail} alt='' style={{ width: "100%", height: "100%" }} />
                                    :
                                    <>
                                        <img className={`mb-3 vidThumb-img  img-fluid ${state === 9 ? "vidThumb-img-1" : ''}`} src={videoThumb} />
                                        <p className={`font-italic mb-0 ${state !== 9 ? "text-light" : ''}`} style={{ fontWeight: "600" }}>
                                            {state === 9 ?
                                                <div className="dots-wrapper">
                                                    <a style={{ paddingRight: "3px" }}>Transcribing</a>
                                                    <span class="loading__dot"></span>
                                                    <span class="loading__dot"></span>
                                                    <span class="loading__dot"></span>
                                                    <span class="loading__dot"></span>
                                                </div> :
                                                state === 8 ? "Awaiting Customization" :
                                                    state === 7 ? "Transcribing Failed" :
                                                        state === 10 ? "Waiting in Local Queue" :
                                                            state === 3 ? "Rendering Failed" :
                                                                state === 5 ? " Added To Local Queue" :
                                                                    state === 11 ? "Downloading Resources" : 'Awaiting Script'}
                                        </p>
                                    </>
                            }

                        </div>

                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.created}</p></div>
                        <div className="vidiconAll">


                            {auth.user.is_client_account !== "1" ? <div className="vidHover-single">
                                {state === 9 ? "" :
                                    <Link to={`/editor?courseId=${curElem.courseId}&chapterId=${curElem.chapterId}`}>
                                        <span className="vidHover-icon"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                        <span>Edit</span>
                                    </Link>
                                }
                            </div> : ""}

                            {auth.user.is_client_account !== "1" ? <div className="vidHover-single cursor-pointer ps-2">
                                <a onClick={handleDeleteAlert}>
                                    <span className="vidHover-icon"><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                    <span>Delete</span>
                                </a>
                            </div> : ""}

                            {curElem.status === "6" ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={`${curElem.videoUrl}`}>
                                            <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    {auth.user.is_client_account !== "1" ? <div className="vidHover-single cursor-pointer">
                                        <a onClick={handlePublish} >
                                            <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                            <span>Publish</span>
                                        </a>
                                    </div> : ""}
                                </>
                                : ''}





                        </div>
                    </div>

                </div>
                <div className="videoProject-single-bottom p-0">
                    <div className="videoProject-single-bottom-desc p-0">
                        <span>{curElem.name}</span>
                    </div>
                </div>
            </div>

            <PublishModal
                showModel={showModel}
                setShowModel={setShowModel}
                curElem={curElem}

            />


            <SweetAlert
                warning
                showCancel
                confirmBtnText={button}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                Do you want to delete this Chapter {curElem.name}
            </SweetAlert>
        </>
    )
}

export default ChapterCards