
export const addCourse = (data) => (dispatch) => {
    dispatch({ type: "ADD_COURSE", payload: data })
}
export const updateCourseName = (name) => (dispatch) => {
    dispatch({ type: "UPDATE_COURSE_NAME", payload: name })
}

export const addTopic = (data) => (dispatch) => {
    dispatch({ type: "ADD_TOPIC", payload: data })
}

export const addChapters = (data) => (dispatch) => {
    dispatch({ type: "ADD_CHAPTERS", payload: data })
}

export const addTranscribeData = (val, chapterId, type) => (dispatch) => {
    const data = { val, chapterId, type }
    dispatch({ type: "ADD_TRANSCRIBE_DATA", payload: data })
}
export const addTextType = (id) => (dispatch) => {
    dispatch({ type: "ADD_TEXT_DATA", payload: id })
}
export const updateChapterText = (text, id) => (dispatch) => {
    const data = { text, id }
    dispatch({ type: "UPDATE_TEXT", payload: data })
}
export const updateChapterTextIfFalse = (text, id) => (dispatch) => {
    const data = { text, id }
    dispatch({ type: "UPDATE_TEXT_IF_FALSE", payload: data })
}
export const setAllCourseIds = (arr) => (dispatch) => {
    dispatch({ type: "ADD_ALL_COURSE_ID", payload: arr })
}

export const updateCourseChapIntroOutro = (type, cIndex, data) => (dispatch) => {
    if (type === "intro") {
        dispatch({ type: "UPDATE_INTRO_COURSE", payload: { cIndex, data } })
    } else {
        dispatch({ type: "UPDATE_OUTRO_COURSE", payload: { cIndex, data } })
    }
}


