import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../actions/authAction";

const ProfilePassword = () => {

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        password: '',
        new_password: '',
        confirm_password: '',
    });

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })

    const onInputChange = (e) => {
        setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{8,}$/;
        if (userDetails.new_password) {
            if (pattern.test(userDetails.new_password)) {
                if (userDetails.new_password === userDetails.confirm_password) {
                    setLoader({
                        ...loader,
                        password: true
                    })
                    dispatch(updatePassword(userDetails, setLoader));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }

            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    // const onFormSubmit = (e) => {
    //     e.preventDefault();
    //     setLoader(true);
    //     dispatch(updatePassword(userDetails, setLoader));

    // }

    return (
        <>


            <div className="profileBlock">
                <h2 className="text-center">Update Password</h2>
                <form className="" method="post" onSubmit={(e) => onFormSubmit(e)}>
                    <div className="form-group form-group-alt">
                        <label className="form-text text-muted">Current Password</label>
                        <input type="password" className="form-control"
                            placeholder="**********" name="password" required
                            onChange={(e) => onInputChange(e)}
                        />
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">New Password</label>
                                <input type="password" className="form-control"
                                    placeholder="**********" name="new_password" required
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Confirm New Password</label>
                                <input type="password" className="form-control"
                                    placeholder="**********" name="confirm_password" required
                                    onChange={(e) => onInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-info my-3 text-center py-2" role="alert">
                        Updating your password will log you out for re-login
                    </div>
                    {passwordMsg.validation ?
                        <p style={{ fontSize: 13, marginLeft: 5, color: "#DC143C" }}>{passwordMsg.msg}</p> : ""}
                    <button type="submit" className="fbtn btn-block demoLink mt-0" disabled={loader}> {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Update</button>

                </form>
            </div>
        </>
    )
}

export default ProfilePassword;