import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../actions/alert'
import { updateCourseName } from '../../actions/courseAction'
import { commonAxios } from '../../global/CommonAxios'

const CourseHeader = (props) => {
    const dispatch = useDispatch()
    const [text, setText] = useState("")

    const handeEditCour = (e) => {
        setText(e.target.value)
        dispatch(updateCourseName(e.target.value))
    }
    const handleSubmit = () => {
        if (text != "") {
            let data = {
                courseId: props.courseId,
                projectName: text,
                topicName: props.topicName
            }
            commonAxios("course-update", data, dispatch, props.config)
                .then((res) => {
                    if (res.status) {
                        console.log("Course Name Update")
                        // dispatch(setAlert(res.msg, "success"))
                    }
                }).catch((err) => {
                    console.log(err)
                })
        }
    }
    useEffect(() => {
        setText(props.courseName)
    }, [props.courseName])

    return (
        <>
            {
                <input
                    style={{ width: text.length + "ch", paddingLeft: "5px" }}
                    value={text}
                    onChange={handeEditCour}
                    className="cour-edit"
                    onBlur={handleSubmit}
                />
            }
        </>
    )
}

export default CourseHeader