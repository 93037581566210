import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../actions/alert";

const useRecorder = () => {
  const dispatch = useDispatch()
  const [audioURL, setAudioURL] = useState("");
  const [recorder, setRecorder] = useState(null);

  const [status, setStatus] = useState({
    start: false,
    pause: false,
    resume: false,
  })
  

  useEffect(() => {
    if (recorder === null) {
      if (status.start) {
        requestRecorder().then(setRecorder, (error) => {
          dispatch(setAlert(error.message + ". Please add Mic input", "warning"))
          setStatus({
            ...status,
            start: false
          })

        })
      }
      return;
    }
    if (status.start) {
      recorder.start();
    } else {
      recorder.stop()
      recorder.stream.getTracks().forEach(track => track.stop());
      setStatus({
        ...status,
        pause: false,
        resume: false
      })
    }

    const handleData = e => {
      setAudioURL(e.data);
      setRecorder(null)
    };

    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, status.start]);


  const startRecording = () => {
    setStatus({
      ...status,
      start: true
    })
  };
  const stopRecording = () => {
    setStatus({
      ...status,
      start: false,
      pause: false,
      resume: false
    })
  };

  const pauseRecording = () => {
    setStatus({
      ...status,
      pause: true,
      resume: false
    })
    recorder.pause()
  };
  const resumeRecording = () => {
    recorder.resume()
    setStatus({
      ...status,
      resume: true,
      pause: false
    })

  };

  return [audioURL, status, recorder, startRecording, stopRecording, pauseRecording, resumeRecording];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}


export default useRecorder;
